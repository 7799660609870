<template>
    <div class="dashboard">
        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">
            <TopNav />

            <div class="heading-lead h3">
                <b>Sales Order</b>

                <a href="/delivery" class="btn btn-warning font-14" style="float: right;background: #f0d558; box-shadow: 0px 1px 12px 6px #00000017; font-weight: 600;">Confirm Delivery</a>                
            </div>

            <div class="content-body">
                <div class="row filter-wrap mb-3">

                    <!-- page tabs -->
                    <div class="col-lg-12">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link" :class="order_tab_active == 'PENDING' ? 'active' : ''" @click="order_tab_active = 'PENDING'" aria-current="page" href="#">Pending Orders</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="order_tab_active == 'MODIFIED' ? 'active' : ''" @click="order_tab_active = 'MODIFIED'" aria-current="page" href="#">Modified Orders</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="order_tab_active == 'Backorder' ? 'active' : ''" @click="order_tab_active = 'Backorder'" aria-current="page" href="#">Open Orders</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="order_tab_active == 'Invoiced' ? 'active' : ''" @click="order_tab_active = 'Invoiced'" aria-current="page" href="#">Invoiced Orders</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a class="nav-link" :class="order_tab_active == 'Delivered' ? 'active' : ''" @click="order_tab_active = 'Delivered'" aria-current="page" href="#">Delivered Orders</a>
                            </li> -->
                            <li class="nav-item">
                                <a class="nav-link" :class="order_tab_active == 'Canceled' ? 'active' : ''"  @click="order_tab_active = 'Canceled'" aria-current="page" href="#">Canceled Orders</a>
                            </li>
                        </ul>
                    </div>


                    <!-- pending orders -->
                    <div class="col-lg-12">
                        <div class="p-4" style="border: 1px solid #dee2e6;border-top: none" v-if="order_tab_active == 'PENDING'">
                            <table class="table table-striped table-hover" v-if="order_tab_active != 'MODIFIED'">
                                <thead>
                                    <tr style="border-bottom: 2px solid #000;">
                                        <th>SN</th>
                                        <th style="width:200px">Order ID</th>
                                        <!-- <th>Sub Total</th> -->
                                        <!-- <th>Freight  Charge</th> -->
                                        <th style="width:85px; text-align: center">AMOUNT</th>

                                        <!-- <th>Total Items Method Location</th> -->
                                        <th>Delivery</th>
                                        <th>Site</th>
                                        <!-- <th>Delivery Address</th> -->
                                        <th>Date</th>
                                        <th>Time</th>
                                        <!-- <th>Status</th> -->
                                        <th scope="col" style="width: 20px;">Action</th>
                                    </tr>
                                </thead>
                                
                                <tbody v-if="isOpen">
                                    <tr v-for="(x,i) in oms_pending_list" :key="i">
                                        <td></td>
                                        <td>{{x.order_no}}</td>
                                        <td class="text-end">&#x20A6;{{ thDFormat(Number(x.amount) + Number(x.freighted_charge)) }}</td>
                                        <!-- <td>&#x20A6;{{ thDFormat(Number(x.amount)) }}</td> -->
                                        <!-- <td>&#x20A6;{{ thDFormat(Number(x.freighted_charge)) }}</td> -->
                                        <!-- <td>{{x.order_items_count}} Item(s)</td> -->

                                        <td>{{x.colection_methodID}}</td>
                                        <td>{{x.loading_locationID}}</td>
                                        <!-- <td>{{x.ship_address}}</td> -->
                                        <td>{{formatDate(x.date_created, 'date')}}</td>
                                        <td>{{formatDate( x.date_created, 'time')}}</td>
                                        <!-- <td>{{x.order_status}}</td> -->
                                        <td>
                                            <a href="#!" @click="view_index3 = i" class="d-block btn-sm font-12 btn btn-success"  data-bs-toggle="modal" data-bs-target="#filterModal4">View</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="modal fade" id="filterModal4" tabindex="-1" aria-labelledby="exampleModalLabe4" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content shadow-sm">
                                    <div class="modal-header">
                                        <h5 class="modal-title color-scope bolder" id="exampleModalLabel">Order Line Items</h5>
                                        
                                        <button type="button" class="btn-close" id="closebtn" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <p v-if="oms_pending_list[view_index3].ship_address" class="font-12">Delivery Address: {{oms_pending_list[view_index3].ship_address}}</p>
                                                    
                                        <table class="table table-striped table-hover">
                                            <thead>
                                                    <tr>
                                                        <td>Item No</td>
                                                        <td>Product Name</td>
                                                        <!-- <td>Unit</td> -->
                                                        <td>Unit Price</td>
                                                        <td>Quantity</td>
                                                        <!-- <td>Suggested Quantity</td> -->
                                                        <td>Price</td>
                                                        <!-- <td>Site</td> -->
                                                        <!-- <td>Line Status</td> -->

                                                    </tr>
                                            </thead>
                                            <tbody>
                                                
                                                <tr v-for="(p,j) in oms_pending_list[view_index3].order_items" :key="j">
                                                    <td> {{p.productID}}</td>
                                                    <td> {{p['products'].productname}}</td>
                                                    <!-- <td> {{p.unit}}</td> -->
                                                    <td> &#x20A6;{{ thDFormat(Number(p.price)) }}</td>
                                                    <td> {{ thDFormat(Number(p.quantity)) }} {{p.unit}}</td>
                                                    <!-- <td> {{ thDFormat(p.suggested_quantity) }}</td> -->
                                                    <td> &#x20A6;{{ thDFormat(Number(p.amount)) }}</td>
                                                    <!-- <td> {{p.site}}</td> -->
                                                    <!-- <td> {{p.linestatus}}</td> -->
                                                </tr>
                                            </tbody>
                                        </table>

                                        <!-- <button class="btn btn-success btn-large text-end" @click="changeModify(oms_order_list[view_index].ID,'APPROVED')" style="margin-right:10px"  data-bs-dismiss="modal" aria-label="Close">Approve</button>
                                        <button class="btn btn-danger btn-large text-end" @click="changeModify(oms_order_list[view_index].ID,'REJECTED')" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                                        -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>       


                    <!-- list from ORM modified -->
                    <div class="col-lg-12">
                        <div class="" style="" v-if="order_tab_active == 'MODIFIED'">
                            <table class="table table-striped table-hover" v-if="order_tab_active != 'PENDING'">
                                <thead>
                                    <tr style="border-bottom: 2px solid #000;">
                                        <th>SN</th>
                                        <th>Order ID</th>
                                        <th>AMOUNT</th>
                                        <!-- <th>Sub Total</th> -->
                                        <!-- <th>Freighted Charge</th> -->
                                        <!-- <th>Total Items</th> -->
                                        <th>Delivery</th>
                                        <th>Site</th>
                                        <!-- <th>Delivery Address</th> -->
                                        <th>Date</th>
                                        <th>Time</th>
                                        <!-- <th>Updated Date</th> -->
                                        <!-- <th>Status</th> -->

                                        <th scope="col" style="width: 20px;">Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="isOpen1">
                                    <tr v-for="(x,i) in oms_order_list" :key="i">
                                        <td></td>
                                        <td>{{x.order_no}}</td>
                                        <td>&#x20A6;{{ thDFormat(Number(x.amount) + Number(x.freighted_charge)) }}</td>
                                        <!-- <td>&#x20A6;{{ thDFormat(Number(x.amount)) }}</td> -->
                                        <!-- <td>&#x20A6;{{ thDFormat(Number(x.freighted_charge)) }}</td> -->
                                        <!-- <td>{{x.order_items_count}} Item(s)</td> -->

                                        <td>{{x.colection_methodID}}</td>
                                        <td>{{x.loading_locationID}}</td>
                                        <!-- <td>{{x.ship_address}}</td> -->
                                        <!-- <td>{{x.date_created}}</td> -->
                                        <!-- <td>{{x.updated_at}}</td> -->
                                        <td>{{formatDate(x.updated_at, 'date')}}</td>
                                        <td>{{formatDate( x.updated_at, 'time')}}</td>
                                        <!-- <td>{{x.order_status}}</td> -->
                                        <td>
                                            <a href="#!" @click="view_index = i" class="d-block btn-sm font-12 btn btn-success"  data-bs-toggle="modal" data-bs-target="#filterModal3">View</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <!-- modified order item from OMS -->
                        <div class="modal fade" id="filterModal3" tabindex="-1" aria-labelledby="exampleModalLabe3" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content shadow-sm">
                                    <div class="modal-header">
                                        <h5 class="modal-title color-scope bolder" id="exampleModalLabel">Order Line Items</h5>
                                        
                                        <button type="button" class="btn-close" id="closebtn" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                                    
                                        <table class="table table-striped table-hover">
                                            <thead>
                                                    <tr>
                                                        <td>S/N</td>
                                                        <td>Item No</td>
                                                        <td>Product Name</td>
                                                        <td>Suggested Product</td>
                                                        <td>Unit Price</td>
                                                        <td>Suggested Unit Price</td>
                                                        <td>Ordered Quantity</td>
                                                        <td>Suggested Quantity</td>
                                                        <td>Unit</td>
                                                        <td>Reason</td>
                                                        <td>Modification Type</td>
                                                        <td>Suggested Total</td>
                                                        <!-- <td>Price</td> -->
                                                        <!-- <td>Site</td> -->
                                                        <!-- <td>Line Status</td> -->

                                                    </tr>
                                            </thead>
                                            <tbody>
                                                
                                                <tr v-for="(p,j) in oms_order_list[view_index].order_items" :key="j" :style="p.modificationtype ? 'background:yellow':''">
                                                    <td></td>
                                                    <td> {{p.productID}}</td>
                                                    <td> {{p['products'].productname}}</td>

                                                    <td> {{ p['modifyproducts'] ? p['modifyproducts'].productname : ''}}</td>
                                                    
                                                    <td> &#x20A6;{{ thDFormat(Number(p.price)) }}</td>
                                                    <td> &#x20A6;{{ thDFormat(Number(p.suggested_price)) }}</td>

                                                    <td> 
                                                        {{ thDFormat(Number(p.quantity)) }} 
                                                        ({{ thDFormat(p.quantity/p['products'].bagcount) }} tonne
                                                        <span v-if="p.quantity/p['products'].bagcount > 1">s</span>) 
                                                    </td>

                                                    <td>
                                                        <span v-if="p.suggested_quantity">
                                                            {{ Number(p.suggested_quantity) }} 
                                                            
                                                            ({{ p.suggested_quantity/p['products'].bagcount }} 
                                                            
                                                            tonne<span v-if="p.suggested_quantity/p['products'].bagcount > 1">s</span>)
                                                        </span>

                                                        <span v-if="!p.suggested_quantity">
                                                            {{ Number(p.quantity) }} 
                                                            
                                                            ({{ p.quantity/p['products'].bagcount }} tonne<span v-if="p.quantity/p['products'].bagcount > 1">s</span>)
                                                        </span>
                                                        
                                                    </td>


                                                    <td> {{p.unit}}</td>
                                                    <td> {{p.reason}}</td>

                                                    <td> {{p.modificationtype == 'quantitychange' ? 'quantity updated' : p.modificationtype == 'productchange' ? 'Product replaced' : ''}} </td>


                                                    <td>                                                
                                                        <span v-if="p.suggested_quantity">                                                
                                                            &#x20A6;{{ thDFormat(Number(p.suggested_quantity) * (p.modificationtype == 'quantitychange' ? Number(p.price) : Number(p.suggested_price))) }}
                                                        </span>
                                                        <span v-else>
                                                            &#x20A6;{{ thDFormat(p.quantity * p.price) }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style="width:150px">Suggested Freighted Charge</td>

                                                    <td style="    text-align: right;">
                                                        &#x20A6;{{

                                                            oms_order_list[view_index].colection_methodID == 'freighted' ?
                                                            
                                                            thDFormat(Number(oms_order_list[view_index].suggested_freighted)) : 0
                                                        
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style="width:150px">Suggested Sub Total</td>

                                                    <td style="    text-align: right;">
                                                        &#x20A6;{{

                                                            oms_order_list[view_index].order_items.length > 0 ?
                                                            
                                                            thDFormat(oms_order_list[view_index].order_items.map(x => (
                                                                x.modificationtype == 'productchange' ? x.suggested_price : x.price) * (x.suggested_quantity ? x.suggested_quantity : x.quantity)).reduce( (p, v) => Number(p) + Number(v))) : 0
                                                        
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style="width:150px">Suggested Grand Total</td>

                                                    <td style="    text-align: right;">
                                                        &#x20A6;{{

                                                            oms_order_list[view_index].order_items.length > 0 ?
                                                            
                                                            thDFormat(oms_order_list[view_index].order_items.map(x => (
                                                                x.modificationtype == 'productchange' ? x.suggested_price : x.price) * (x.suggested_quantity ? x.suggested_quantity : x.quantity)).reduce( (p, v) => Number(p) + Number(v)) + Number(oms_order_list[view_index].suggested_freighted) ) : 0
                                                        
                                                        }}
                                                    </td>
                                                </tr>

                                            </thead>
                                        </table>

                                        <button class="btn btn-success btn-large text-end" @click="changeModify(oms_order_list[view_index].ID,'APPROVED')" style="margin-right:10px"  data-bs-dismiss="modal" aria-label="Close">Accept</button>
                                        <button class="btn btn-danger btn-large text-end" @click="changeModify(oms_order_list[view_index].ID,'REJECTED')" data-bs-dismiss="modal" aria-label="Close" style="margin-right:10px">Cancel</button>
                                        <button class="btn btn-info btn-large text-end text-white" data-bs-dismiss="modal" aria-label="Close" >Close</button>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- list from AX -->
                    <div class="col-lg-12">
                        <div class="filter-area" data-bs-toggle="modal" data-bs-target="#filterModal">
                            <span>
                                <svg width="33" height="33" style="margin-right:30px; top: 0px;" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8333 28.5H22.1667V25.3333H15.8333V28.5ZM4.75 9.5V12.6667H33.25V9.5H4.75ZM9.5 20.5833H28.5V17.4167H9.5V20.5833Z" fill="#006E40"/></svg>
                            </span>
                            <small>  Filter  </small>
                        </div>

                        <!-- search - filter order from AX -->
                        <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content shadow-sm">
                                    <div class="modal-header">
                                        <h5 class="modal-title color-scope bolder" id="exampleModalLabel">Filter</h5>
                                        <button type="button" class="btn-close" id="closebtn" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">

                                        <p class="bolder mb-3 mt-5">Select date range</p>

                                        <div class="row">
                                            <div class="col-12">                                    
                                                <input type="date" v-model="startdate" class="form-control border-b" style="height: 55px;">
                                            </div>
                                            <div class="col-12 mt-3">                                               
                                                <input type="date" v-model="enddate" class="form-control border-b" style="height:55px">
                                            </div>

                                            <div class="col-12 mt-3">                                               
                                                <button type="button" @click="filterResult()" class="btn scope-bg btn-success col-12" style="height:55px">Filter</button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="" style="" v-if="order_tab_active != 'MODIFIED'">
                            <table class="table table-striped table-hover" v-if="order_tab_active != 'PENDING'">
                                <thead>
                                    <tr style="border-bottom: 2px solid #000;">
                                        <th>SN</th>
                                        <th>order ID</th>
                                        <!-- <th>Description</th> -->
                                        <th scope="col" class="pointer" :class="[order_set=='date' ? 'active':'', theorder==1?'up':'down']">
                                            Date  
                                            <i class="fa fa-caret-up" style="top:-3px" @click="sortBy('date')"></i> 
                                            <i class="fa fa-caret-down" @click="reverseSort('date')"></i>
                                        </th>
                                        <th scope="col" class="pointer" :class="[order_set=='deliveryterms' ? 'active':'', theorder==1?'up':'down']">
                                            Delivery Terms  
                                            <i class="fa fa-caret-up" style="top:-3px" @click="sortBy('deliveryterms')"></i> 
                                            <i class="fa fa-caret-down" style="right: 10px;top: 4px;" @click="reverseSort('deliveryterms')"></i>
                                        </th>
                                        <th scope="col" class="pointer" :class="[order_set=='deliveryaddress' ? 'active':'', theorder==1?'up':'down']">
                                            Delivery Address 
                                            <i class="fa fa-caret-up" style="top:-3px" @click="sortBy('deliveryaddress')"></i> 
                                            <i class="fa fa-caret-down" style="right: 10px;top: 4px;" @click="reverseSort('deliveryaddress')"></i>
                                        </th>
                                        <!-- <th scope="col" class="pointer" :class="[order_set=='warehouse' ? 'active':'', theorder==1?'up':'down']">
                                            Warehouse 
                                            <i class="fa fa-caret-up" style="top:-3px" @click="sortBy('warehouse')"></i> 
                                            <i class="fa fa-caret-down" style="right: 10px;top: 4px;" @click="reverseSort('warehouse')"></i>
                                        </th>-->

                                        <!-- <th scope="col" style="width: 65%;">Sales Order Line</th> -->
                                        <th scope="col" style="width: 20px;">ETA</th>
                                        <th scope="col" style="width: 20px;">View</th>
                                        <th scope="col" style="text-align: center;width: 100px;">Delivery Status</th> 
                                        <!-- <th scope="col" style="width: 20px;">Action</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <!--  :class="x.salesorderline[0].linestatus == order_tab_active ? '' : 'd-none'" -->
                                    <tr v-for="(x,i) in order_list" :key="i" :class="x.salesorderline[0].linestatus == order_tab_active ? '' : 'd-none'">
                                        <td>{{i}}</td>

                                        <td>{{x.orderID}}</td>
                                        <!-- <td></td> -->
                                        <td>{{x.date}}</td>
                                        <td>{{x.deliveryterms == 'CFR' ? 'FMN Delivery' : 'Self Collection'}}</td>
                                        <td>{{x.deliveryaddress}}</td>
                                        <!-- <td>{{x.warehouse}}</td> -->
                                        
                                        <td>{{x.date}}</td>
                                        <td>
                                            <u><a href="#!" @click="view_index2 = i" class="d-block color-scope"  data-bs-toggle="modal" data-bs-target="#filterModal2">View</a></u>
                                        </td>

                                        <td style="text-align: center;">
                                            <span v-if="x.salesorderline[0].linestatus != 'Delivered'">
                                                <span v-if="x.salesorderline[0].linestatus == 'Canceled'">
                                                    Canceled
                                                </span>
                                                <span v-else>
                                                    <span v-if="x.podstatus != ''">
                                                        <span v-if="x.podstatus == 1">
                                                            Delivered
                                                        </span>
                                                        <span v-else>                                                    
                                                            <a href="#!" @click="confirmPOD(x.orderID);" class="d-block btn-sm font-12 btn btn-success" title='Click to approve proof of delivery' style="font-size: 10px; padding: 3px 0px;">Confirm Delivery</a>
                                                        </span>
                                                    </span>
                                                    <span v-else>
                                                        Pending
                                                    </span>                                                
                                                </span>
                                            </span>                                            
                                            <span v-else>
                                                {{x.salesorderline[0].linestatus}}
                                            </span>
                                        </td>

                                        <td>
                                            <!-- <u v-if="x.deliveryterms == 'CFR'">
                                                <span v-if="x.salesorderline[0].linestatus == 'Invoiced'" target="blank" class="d-block color-scope">
                                                    <a v-if="x.tracking_link != ''" :href="x.tracking_link">Track</a>
                                                    <span v-else  @click="$swal.fire('Alert','No Tracking Available', 'error')" >Track</span>
                                                </span>
                                            </u> -->
                                            
                                            <u v-if="x.deliveryterms == 'CFR'">
                                                <span v-if="x.salesorderline[0].linestatus == 'Invoiced'" target="blank" class="d-block color-scope">
                                                    <a v-if="x.tracking_link != ''" :href="x.tracking_link">Proof of delivery</a>
                                                </span>
                                            </u>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                     
                        <!-- order item from AX -->
                        <div class="modal fade" id="filterModal2" tabindex="-1" aria-labelledby="exampleModalLabe2" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content shadow-sm">
                                    <div class="modal-header">
                                        <h5 class="modal-title color-scope bolder" id="exampleModalLabel">Order Line Items</h5>
                                        
                                        <button type="button" class="btn-close" id="closebtn" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                                    
                                        <table class="table table-striped table-hover">
                                            <thead>
                                                    <tr>
                                                        <td>S/N</td>
                                                        <td>Item No</td>
                                                        <td>Product Name</td>
                                                        <td>Unit Price</td>
                                                        <td>Quantity</td>
                                                        <td>Unit</td>
                                                        <td>Price</td>
                                                        <td>Site</td>
                                                        <td style="width:90px">Line Status</td>

                                                    </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(p,j) in order_list[view_index2].salesorderline" :key="j">
                                                    <td></td>
                                                    <td> {{p.itemno}}</td>
                                                    <td> {{p.productname}}</td>
                                                    <td> &#x20A6;{{ thDFormat(Number(p.unitprice)) }}</td>
                                                    <td> {{ thDFormat(Number(p.quantity)) }}</td>
                                                    <td> {{p.unit}}</td>
                                                    <td> &#x20A6;{{ thDFormat(Number(p.price)) }}</td>
                                                    <td> {{p.site}}</td>
                                                    <td> {{p.linestatus == 'Backorder' ? 'Open Order' : p.linestatus}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Copyright />
                       

            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import moment from 'moment';


import axios from 'axios';

export default {
    name: "Orders",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            page_active:"orders",         
            
            order_list:[
                {
                    "salesorderline":[
                        {"linestatus":"", "unitprice":"", "price":"", "itemno":"", "productname":"","unit":"","quantity":"", "site":""}
                    ]
                }
            ],

            obj_order:{},

            subaccountid:'',
            currentBalance:'',
            creditRemaining:'',
            avaiableBalance:'',
            sub_acct:'',
            order_set:'',
            theorder:'',

            startdate:'',
            enddate:'',
            nodata:0,
            view_index:0,
            view_index2:0,
            view_index3:0,
            order_tab_active :'PENDING',
            oms_order_list:[{"order_items":[]}],
            oms_pending_list:[{"order_items":[]}],
            isOpen:false,
            isOpen1:false,
            isOpen2:false,
            pod:{}
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Dashboard";
            },
        },
    },

    mounted () {  
        this.inactivityTime(); 
        if (this.$store.getters.vendor_no) {
            this.phone      = this.$store.getters.phone; 
            this.vendor_no  = this.$store.getters.vendor_no;    
            this.sub_acct   = this.$store.getters.sub_acct;            
        }
        
        this.obj_order.startdate = moment().subtract(2, 'months').format('YYYY/MM/DD');
        this.obj_order.enddate = moment().add(1, 'days').format('YYYY/MM/DD');

        this.getSalesOrders(); // load sales order history from AX
        this.getOMSOrders(); // load sales order history from 
        this.getPendingOrders(); // load sales order history from 
    },

    methods: {
        async getSalesOrders () {
            this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false, allowOutsideClick: false});     
            this.obj_order.subaccountID = this.sub_acct;

            this.obj_order.page = 2;
            this.obj_order.pagesize = 100;
            this.obj_order.linestatus = "";
                this.obj_order.subaccountID = this.$store.getters.sub_acct;

            let data = this.loadNew(this.obj_order);
            try {                                 
                await axios({
                    method: "get",
                    url: this.$hostname+"/getsaleordertransactions",
                    params: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    if(res.data.data.length > 0){
                        this.order_list = res.data.data;
                        this.reverseSort('date');
                    }


                    if(res.data.data.length == 0){
                         this.nodata2 = true;
                    }

                    this.$swal.close();


                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },
    
        async getOMSOrders () {
            try {           
                
                let data = this.loadNew({"status":"MODIFIED", "sub_accountID": this.sub_acct, "subaccountID":this.$store.getters.sub_acct});                      
                await axios({
                    method: "get",
                    url: this.$hostname+"/modifysalesorder",
                    params:{'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    if(res.data.data.data.length > 0){
                        this.isOpen1 = true;
                        this.oms_order_list = res.data.data.data;


                        
                        this.oms_updated_total =  parseFloat(res.data.data.data.map(x => x.amount).reduce( (p, v) => Number(p) + Number(v) ) + res.data.data.data.map(x => x.freighted_charge).reduce( (p, v) => Number(p) + Number(v) ));

                    }

                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },
    
        async getPendingOrders () {
            try {              
                let data = this.loadNew({"status":"PENDING", "sub_accountID": this.sub_acct,"subaccountID":this.$store.getters.sub_acct});                      
                await axios({
                    method: "get",
                    url: this.$hostname+"/modifysalesorder",
                    params: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    if(res.data.data.data.length > 0){
                        this.isOpen = true;
                        this.oms_pending_list = res.data.data.data;

                        
                        this.oms_pending_total = parseFloat(res.data.data.data.map(x => x.amount).reduce( (p, v) => Number(p) + Number(v) ) + res.data.data.data.map(x => x.freighted_charge).reduce( (p, v) => Number(p) + Number(v) ));
                    }

                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },

        async changeModify (x,y){
            //
           // let sub_acct_bal = Number(this.$store.getters.sub_acct_bal) - (Number(this.oms_pending_total) + Number(this.oms_updated_total));
           // let mbal = oms_order_list[x].order_items
            
            this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false}); // preloader

            console.log(x,y);
            try {                
                
                    let data = this.loadNew({"id":x, "status": y,"subaccountID":this.$store.getters.sub_acct});

                await axios({
                    method: "post",
                    url: this.$hostname+"/postmodifiedorders",
                    data: {'token':data} ,
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    this.oms_order_list = [{"order_items":[]}];
                    this.getOMSOrders();

                    this.fetchAccounts(res.data.message);                    

                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            } 
        },

        sortBy (x){
            this.order_set = x;
            this.theorder = 1;
            this.order_list.sort(function(a, b) {
                
                var nameA = a[x].toUpperCase(); // ignore upper and lowercase
                var nameB = b[x].toUpperCase(); // ignore upper and lowercase

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });
        },

        reverseSort (x){
            this.order_set = x;
            this.theorder = 2;
            this.order_list.sort(function(a, b) {
                
                var nameA = a[x].toUpperCase(); // ignore upper and lowercase
                var nameB = b[x].toUpperCase(); // ignore upper and lowercase

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });

            this.order_list.reverse();
        },

        filterResult (){
            if(this.startdate != "" && this.enddate !== ""){
                this.obj_order.startdate    = moment(String(this.startdate)).format('YYYY/MM/DD');
                this.obj_order.enddate      = moment(String(this.enddate)).format('YYYY/MM/DD');

                this.getSalesOrders();
                document.getElementById("closebtn").click();
            }
        },

        fetchAccounts (x) {
            
            this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false}); // preloader

                    let data = this.loadNew({"subaccountID":this.$store.getters.sub_acct});
            try {
                axios({
                    method: "get",
                    url: this.$hostname+"/getsubaccountID",
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  },
                    params:{'token':data},
                })

                .then(res => {
                    if(res.data.status == true){
                        this.$store.commit("set_subaccounts", res.data.data);

                        let sub_acct   = this.$store.getters.sub_acct
                        let newbal     = res.data.data.filter( el => el.customeraccount == sub_acct );
                        this.$store.commit("set_sub_acct_bal", newbal[0].creditremaining);
                        
                        this.subprofiles = this.$store.getters.subaccounts;

                        this.$swal.close();
                        this.$swal.fire(
                                    'Success Alert',
                                    x,
                                    'success'
                                )

                    }
                })
            }
            catch (err) {           
                console.log(err);
            }            
        },

        confirmPOD(orderID){
                // if(this.pod.otp != undefined){
            if(confirm("Please confirm that your goods have been delivered")){
                this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false, allowOutsideClick: false});     

                this.pod.subaccountID = this.$store.getters.sub_acct;                   
                this.pod.saleorderno = orderID;
                
                // console.log("iisee", this.pod)

                let data = this.loadNew(this.pod);
                axios({
                    method: "post",
                    url: this.$hostname + "/approvepod",
                    data: {'token':data},
                    headers: { "Content-Type": "application/json",
                        "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  },
                }).then(res => {
                    this.response = res.data
                    if(this.response.status == true){
                        this.getSalesOrders();
                        this.$swal.fire(
                            'Success Alert',
                            this.response.message,
                            'success'
                        );
                    }
                    else{
                        this.$swal.fire(
                            'Error Alert',
                            this.response.message,
                            'error'
                        );
                    }                        
                })
                .catch(error => {
                    console.log(error)
                    this.$swal.fire(
                        'Error Alert',
                        'Error occured',
                        'error'
                    );
                    this.is401(error.response.status);
                }).finally(e => {                    
                    console.log(e);
                })
            }
            // else{
            //     this.$swal.fire(
            //         'Error Alert',
            //         'Please enter your POD code.',
            //         'error'
            //     );
            // }   
        },

    },

    created () {
    },

    
    computed: {

    },
};
</script>

<style scoped>
.card-text{color: #5a5555;}
.card-title{font-size: 35px;}
.card-body.bg-border {border: 1px solid #006e40b3; box-shadow: none;}
th.active.up > i.fa-caret-up{color:red}
th.active.down > i.fa-caret-down{color:red}
th i {
    font-size: 14px;
}
th i.fa-caret-down{right: 9px !important;top: 4px;}
.filter-area {
    padding: 10px;
    background: #ffffff;
    border: 3px solid #006e40;
    margin-bottom: 10px;
    position: fixed;
    z-index: 100;
    right: 40px;
    width: 57px;
    bottom: 40px;
    box-shadow: 0px 1px 20px 1px #0000007d;
}
.modal-dialog { max-width: 20%; margin: 0 0 0 80%;}
.modal {z-index: 9999;width: 100%;height: 100%;}
.modal-content {height: 100vh;border-radius: 0;border: none;border-left:1px solid #dee2e6 !important;}
.modal-title {font-size: 19px;}
.modal-header { border-bottom: 1px solid #dee2e638;}
.border-b{border:2px solid #c1c1c1}

.nav-link{
    font-weight: 600;
    color: #000 !important;
    /* padding: 10px 30px; */
}
.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    background: #f7f7f7;
}
.nav-link.active,.nav-link.active:hover {
    color: #495057;
    border-color: #dee2e6 #dee2e6 #fff !important;
    border-radius: 0;
}
.filter-wrap tbody, td, tfoot, th, thead, tr {
    text-transform: uppercase;
}
.table thead th {
    font-weight: 600;
    font-size: .785rem;
    text-transform: uppercase;
    /* letter-spacing: .0625rem; */
}
.nav-link:hover, .nav-link:focus {    
    border-color: #f7f7f7 #f7f7f7 #dee2e6 #f7f7f7 !important;
}






/* .nav-link.active{ 
    border-bottom: 4px solid #006e40 !important;
    border: none;
    top: 2px;
    border-radius: 3px;
} */

.nav-item{margin-right: 10px;}
/* .nav-tabs {border-bottom: 1px solid #dee2e638;} */


#filterModal2 .modal-dialog, #filterModal3 .modal-dialog, #filterModal4 .modal-dialog {
    max-width: 69.2% !important;
    margin: 0 auto !important;
}




table {
    counter-reset: rowNumber;
}

table tbody tr {
    counter-increment: rowNumber;
}

table tbody tr td:first-child::before {
    content: counter(rowNumber);
    margin-right: 0.5em;
}

.modal-body{
    overflow-y: scroll;
}
</style>