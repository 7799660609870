<template>
    <div class="container">

        <LogoTop />

        <div class="row">
            <div class="col-lg-6 d-none d-sm-none d-lg-block">
                <img src="@/assets/images/login-side-image.svg" alt="Side Image" />
            </div>

            <div class="col-lg-5 offset-lg-1 col-sm-8 offset-sm-2 col-12 form-cover">
                <h1 class="welcome-h1">
                    Hello,<br />
                    Welcome back
                </h1>

                <form  class="login-form" autocomplete="off" v-on:submit.prevent="handleLogin" method="post">
                    <div class="form-group">                        
                        <span><b class="fas fa-asterisk text-danger font-12"></b> Username</span>
                        <input type="text" autofocus class="form-control" autocomplete="off" placeholder="Username" v-model="login.username" required />
                        <i class="far fa-user"></i>
                    </div>

                    <div class="form-group">                    
                        <span><b class="fas fa-asterisk text-danger font-12"></b> Password</span>
                        <input :type="passwordFieldType" autocomplete="off" onpaste="return false;" onCopy="return false" ondrop="return false;" class="form-control" placeholder="Password" id="password_field" v-model="login.password" required />
                        <i @click="passwordFieldType == 'password' ? passwordFieldType = 'text' : passwordFieldType = 'password'" :class="passwordFieldType == 'password' ? 'far fa-eye' : 'fa fa-eye-slash'"></i>
                    </div>

                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="remember-me"/>
                        <label class="form-check-label" for="remember-me">Remember me</label>

                        <div class="forgot-pass">
                            <a href="/forgot-password">Forgot Password?</a>
                        </div>
                    </div>

                    <div :class="msg_status" v-html="msg_content"></div>

                    <button type="submit" class="btn form-control login-btn" :disabled='isDisabled'>{{processBtn}}</button>

                    <div class="text-center mt-4">
                        <p class="font-14">
                            Don’t have an account? <a href="/registration"><u>Register</u></a>
                        </p>
                    </div>
                </form>
            </div>
        </div>

        <Copyright />
    </div>
</template>


<script>
import LogoTop from '@/components/LogoTop.vue';
import Copyright from '@/components/Copyright.vue';
import axios from 'axios';

export default {
    name: "Login",
    components: {
        LogoTop, Copyright
    },
    
    data() {
        return{
            passwordFieldType: 'password',
            isDisabled: false,
            processBtn: 'LOGIN',
            msg_status: '',
            msg_content: '',
            login:{}
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN E-Commerce | Login Page";
            },
        },
    },

    methods: {
        async handleLogin () {
            try {
                this.msg_status     = '';
                this.msg_content    = '';

                if(this.login.username != '' && this.login.password != ''){                        
                    this.isDisabled = true,
                    this.processBtn = 'Processing...';

                    let data = this.loadNew(this.login);
                    await axios({
                        method: "post",
                        url: this.$baseurl,
                        data: {'token':data},
                        headers: { "Content-Type": "application/json" },
                    })                    
                    .then(res => {
                        this.response = res.data
                        if(this.response.status == true){

                            this.msg_status     = 'alert alert-success mt-4';
                            this.msg_content    = this.response.message;
                            this.processBtn     = 'Loading...';
                            
                            this.$store.commit("set_phone", this.response.phone);
                            this.$store.commit("set_vendor_no", this.response.vendor_no);
                            this.$store.commit("set_subaccounts", this.response.data.data);
                            this.$store.commit("set_headerstoken", this.response.token);
                            
                            this.$store.commit("set_username", this.login.username);

                            localStorage.isLoggedIn = true;

                            // open next view
                            this.$router.push('/home');
                        }
                        else if(this.response.status == false && this.response.error == "otp"){

                            this.msg_status     = 'alert alert-success mt-4';
                            this.msg_content    = this.response.message;
                            this.processBtn     = 'Loading...';


                            this.$store.commit("set_phone", this.response.phone);
                            this.$store.commit("set_vendor_no", this.response.vendor_no);
                            
                            // open OTP view
                            this.$router.push('/otp-validation');
                        }
                        else{
                            this.msg_status     = 'alert alert-danger mt-4';
                            this.msg_content    = this.response.message;                       
                            this.isDisabled     = false;
                            this.processBtn     = 'LOGIN';

                            if(this.response.reset){
                                this.$swal.fire(
                                    'Alert',
                                    this.msg_content,
                                    'error'
                                ).then(okay => {
                                    if (okay) {
                                        window.location.href = "/forgot-password";
                                    }
                                })
                            }
                        }                        
                    })

                    .catch(error => {
                        console.log(error)
                        this.is401(error.response.status)
                    })  

                    .finally(e => {                                       
                        this.isDisabled = false;
                        this.processBtn = 'LOGIN';
                        console.log(e);
                    })
                }
                else{
                    this.msg_status     = 'alert alert-danger mt-4';
                    this.msg_content    = "Complete all fields to login.";
                }
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            }            
        }
    },

    beforeMount(){
        this.$swal.close();
        localStorage.clear();
    },

    mounted () {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('sessionexpire');

        if(myParam){
            this.$swal.fire('Session Alert', 'You are now logged out due to inactivity.', 'success')
        }
    }
};
</script>