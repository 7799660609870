<template>
    <div class="dashboard">
        <!-- added sidebar from component -->
        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">
            <!-- added topnav from components -->
            <TopNav />

            <div class="content-body">
                <!-- page breadcrumb -->
                <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><b>Accounts</b></h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Accounts</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>

                <!-- All SUb Account -->
                <div class="row mt-4">
                    <div class="col-lg-12">                     
                        <div class="card shadow-sm">
                            <div class="card-body mt-2">
                                <h5>
                                    <b style="border-bottom: 1px solid #000;">Contact Information</b>
                                </h5>
                                <p class="mb-1"><b>Phone Number: &nbsp; </b>{{subprofiles[0].telephone}}</p>
                                <p><b>Email Address: &nbsp;&nbsp;&nbsp;&nbsp;</b>{{subprofiles[0].emailaddress}}</p>

                                <h5 class="mt-5">
                                    <b style="border-bottom: 1px solid #000;">Account Information</b>
                                </h5>
                                
                                <div class="" style="overflow-x:scroll; width:100%;">
                                    <table class="table table-striped table-hover">
                                        <thead>                                    
                                            <tr style="border-bottom: 1px solid #000;">
                                                <th scope="col">Account ID</th>
                                                <th scope="col">Account Name</th>
                                                <th scope="col">Credit Enabled</th>
                                                <th scope="col">Current Balance</th>
                                                <th scope="col">Open Order</th>
                                                <th scope="col">Credit Limit</th>
                                                <th scope="col">Available Balance</th>
                                                <th scope="col">Account Sales Officer</th>
                                                <th scope="col">Sales Officer Phone</th>
                                                <th scope="col">Account Statement</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(x,i) in subprofiles" :key="i">
                                                <td><span>{{x.customeraccount}}</span></td>
                                                <td><span>{{x.customername}}</span></td>
                                                <td><span>{{x.customergroup == 'TradeCash' ? 'No':'Yes'}}</span></td>
                                                <td><span>&#x20A6;{{thDFormat(x.balanceincurrency)}}</span></td>
                                                <td><span>&#x20A6;{{thDFormat(x.ordered)}}</span></td>
                                                <td><span>&#x20A6;{{thDFormat(x.creditlimit)}}</span></td>
                                                <td><span>&#x20A6;{{thDFormat(x.creditremaining) }}</span></td>
                                                <td><span>{{x.accountreceivableofficer}}</span></td>
                                                <td><span>{{x.accountreceivableofficerphone}}</span></td>

                                                <td><span style="text-decoration:underline;color:blue;cursor:pointer" @click="account_selected = x.customeraccount" data-bs-toggle="modal" data-bs-target="#daterange">View Statement</span></td>
                                                <!-- <td><a :href="'account-statement/'+x.customeraccount" style="text-decoration:underline;color:blue">View Statement</a></td> -->
                                            </tr>

                                            <tr style="border-bottom: 1px solid #000;border-top: 1px solid #000;">
                                                <td><b>Total Balance</b></td>
                                                <td></td>
                                                <td></td>
                                                <td><b>&#x20A6;{{thDFormat(totalCurrentBalance) }}</b></td>
                                                <td><b>&#x20A6;{{thDFormat(totalOpenOrder) }}</b></td>
                                                <td></td>
                                                <td><b>&#x20A6;{{thDFormat(totalCreditBalance) }}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                
                                <div>
                                    <!-- <a href="/fund-wallet" class="btn order-btn" style="top: -9px; border-radius:4px;right: 0px;">FUND ACCOUNT</a> -->
                                    <a href="/transfer-fund" class="btn transfer-btn" style="top: -9px; border-radius:4px;margin-right: 10px;">TRANSFER FUND</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Copyright />

                <!-- Modal -->
                <div class="modal fade mt-5" id="daterange" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog mt-5 daterange" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Date Range</h5>

                                <button type="button" class="close btn btn-danger" data-bs-dismiss="modal" aria-label="Close">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                            <div class="modal-body p-3">
                                <div class="form-group">
                                    <b>Start Date:</b>
                                    <input type="date" class="form-control" v-model="date_range.start_date">
                                </div>

                                <div class="form-group mt-3">
                                    <b>End Date:</b>
                                    <input type="date" class="form-control" v-model="date_range.end_date">
                                </div>
                            </div>

                            <div class="modal-footer">
                                <!-- <button type="button" class="btn btn-secondary" data-bs--dismiss="modal">Close</button> -->
                                <button type="button" class="btn text-white bg-scope" data-bs-dismiss="modal" @click="viewStatement()">View Statememt</button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import axios from 'axios';


export default {
    name: "Wallet",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{            
            page_active:"wallet",   
                      
            subprofiles:[
                {
                    telephone:'',
                    emailaddress:'',
                    balanceincurrency:0,
                    ordered:0,
                    creditlimit:0,
                    creditremaining:0,
                }
            ],


            sub_acct:'',
            vendor_no:"",
            account_selected:'',
            date_range:{},
            

            obj_transaction:{},
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Wallet";
            },
        },
    },

    mounted () {      
        this.inactivityTime(); 
        this.subprofiles =  this.$store.getters.subaccounts;
        this.fetchAccounts();
    },

    methods: {
        async fetchAccounts () {
            try {
                
                let data = this.loadNew({"subaccountID":this.$store.getters.sub_acct});

                await axios({
                    method: "get",
                    url: this.$hostname+"/getsubaccountID",
                    params: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    if(res.data.status == true){
                        this.$store.commit("set_subaccounts", res.data.data);

                        let sub_acct   = this.$store.getters.sub_acct
                        let newbal = res.data.data.filter( el => el.customeraccount == sub_acct );
                        this.$store.commit("set_sub_acct_bal", newbal[0].creditremaining);
                        
                    }
                })

                .catch(error => {
                    console.log(error)                    
                    this.is401(error.response.status);
                }) 
            }
            catch (err) {           
                console.log(err);
            }            
        },


        viewStatement(){
            if(this.date_range.start_date !== undefined && this.date_range.end_date !== undefined){
                this.$store.commit("set_account_statement_startdate", this.date_range.start_date);
                this.$store.commit("set_account_statement_enddate", this.date_range.end_date);
                this.$router.push('account-statement/'+this.account_selected);
            }
            else{
                // alert('Please enter start date and end date.')
                this.$swal.fire(
                                'Success Alert',
                                'Please enter start date and end date.',
                                'success'
                            )
                
            }
        }        
    },

    created () {
    },

    computed: {
        totalCreditBalance(){
            let sum = 0;
            for(let i = 0; i < this.subprofiles.length; i++){
                sum +=  Number(this.subprofiles[i].creditremaining);
            }
            return sum;
        },
        totalCurrentBalance(){
            let sum = 0;
            for(let i = 0; i < this.subprofiles.length; i++){
                sum +=  Number(this.subprofiles[i].balanceincurrency);
            }
            return sum;
        },
        totalOpenOrder(){
            let sum = 0;
            for(let i = 0; i < this.subprofiles.length; i++){
                sum +=  Number(this.subprofiles[i].ordered);
            }
            return sum;
        }
    },

    beforeMount(){

    },
};
</script>

<style scoped>

</style>

