<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default {
    components: {
      
    },
    data () {
      return {
        
      }
    },
    mounted () {     

      let current_page = this.$route.name;

      if(current_page == 'Login' || current_page == 'ForgotPassword' || current_page == 'ResetPassword' || current_page == 'Registration' || current_page == 'OTPValidation' || current_page == 'ForgotPasswordSuccess'){
        console.log("Page Passed")
      }
      else{
        if(!localStorage.isLoggedIn){
              this.$router.push('/');
        }
      } 

    },
  }
</script>

<style>

</style>