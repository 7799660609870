<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><b>Order Summary</b></h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item"><a href="/cart">cart</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Order Summary</li>
                            </ol>
                        </nav>
                    </div>
                </div>


                <div v-if="product_list.length > 0">
                    <!-- edit cart -->
                    <div class="row">

                        <div class="col-12 text-end mt-3">
                            <a href="/cart" class="btn bg-scope btn-sm text-white bolder font-12">EDIT CART</a>
                        </div>
                        <div class="col-12 mt-3">
                            <h4 class="sub-name">Total Order Size: {{Math.ceil(totalTons)}} Tonne{{totalTons > 1 ? 's':''}}</h4>
                        </div>
                    </div>


                    <div class="mt-3"  v-for="(truck,i) in sales_data" :key="i">
                        <div class="p-2 shadow-sm border">

                            <!-- cart listings -->
                            <div class="row mt-3"> 
                                <div class="col-12">
                                    <u style="padding-left: 15px;"><b>Order {{i+1}}</b></u>
                                </div>
                                <div class="col-lg-4 mb-4"  v-for="(x, n) in truck" :key="n + '' + i">
                                    <div class="card-body d-flex">
                                        <div class="p-2">
                                            <img :src="x.product.image" style="width: 100px;" v-if="x.product.image" />   
                                            <img src="https://www.caretastic.in/upload/productimg/imagenotfound.jpg" style="width: 100px;" v-else />
                                        </div>
                                        
                                        <div class="ml-10">
                                            <b>{{x.product.productname}}</b> <br>
                                            <small class="font-12">
                                                
                                                <p style="font-size:1px">{{x.qty = Math.floor(x.newunit * x.product.bagcount)}}</p>
                                                <!-- <p style="font-size:1px">{{x.items = Number(x.newunit) * Number(x.product.bagcount)}}</p> -->

                                                {{Math.floor(x.newunit * x.product.bagcount)}}

                                                <span v-if="x.qty == 1">{{unitFormatter(x.product.unit)}}</span>
                                                <span v-else>{{unitFormatter(x.product.unit)}}s</span>  x &#x20A6;{{numTH(x.product.price)}} <br> ({{Math.ceil(x.newunit)}} Tonne{{x.newunit > 1 ? 's':''}})

                                                <br><small>Update Items in quantity</small><br>

                                                <!-- <i v-if="truck.map(y => y.newunit).reduce((p, v) => Number(p) + Number(v)) < 30" class="fa fa-plus" @click="x.newunit = Number(x.newunit) + 1" style="background:green; color:#fff; padding:10px; border-radius:50px"></i>
                                                 <i v-else class="fa fa-plus" style="background:#000; color:#fff; padding:10px; border-radius:50px"></i>

                                                <input type="number" v-model="x.newunit" disabled style="width: 88px;margin: 5px">
                                                
                                                <i  v-if="x.newunit > 1" class="fa fa-minus" @click="x.newunit = Number(x.newunit) - 1" style="background:green; color:#fff; padding:10px; border-radius:50px"></i>
                                                <i v-else class="fa fa-minus" style="background:#000; color:#fff; padding:10px; border-radius:50px"></i> -->

                                                <!-- <input type="hidden" :value="x.items = Number(x.newunit) * Number(x.product.bagcount)"> -->

                                                <i v-if="truck.map(y => y.newunit).reduce((p, v) => Number(p) + Number(v)) < 30" class="fas fa-plus" @click="x.qty = Number(x.qty) + 1; x.newunit = x.qty/x.product.bagcount" style="background:green; color:#fff; padding:10px; border-radius:50px"></i>
                                                <i v-else class="fas fa-plus" style="background:#000; color:#fff; padding:10px; border-radius:50px"></i>

                                                <input type="number" @input="x.newunit = x.qty/x.product.bagcount" disabled v-model="x.qty" style="width: 88px;margin: 5px">

                                                <i  v-if="x.newunit > 1" class="fa fa-minus" @click="x.qty = Number(x.qty) - 1; x.newunit = x.qty/x.product.bagcount" style="background:green; color:#fff; padding:10px; border-radius:50px"></i>
                                                <i v-else class="fa fa-minus" style="background:#000; color:#fff; padding:10px; border-radius:50px"></i>


                                            </small> <br>
                                            <span class="font-12 bolder">&#x20A6;{{numTH(x.product.price * x.qty)}}</span>
                                        </div>
                                    </div>                              
                                </div>
                            </div>


                            <div class="row mt-4">
                                
                                <div class="col-lg-6 offset-lg-5">
                                    <table class="table table-hover">
                                        <tbody>
                                            <tr>
                                                <td>Order Size:</td>
                                                <td style="text-align:right; width:3px"></td>
                                                <td style="text-align:right; width:10px">
                                                    <b>
                                                       {{Math.ceil(truck.map(y => y.newunit).reduce((p, v) => Number(p) + Number(v)))}} Tonnes
                                                    </b>
                                                </td>
                                            </tr>

                                            <tr v-if="deliveryMode=='self'">
                                                <td>Recommended Truck Size:</td>
                                                <td style="text-align:right; width:3px"></td>
                                                <td style="text-align:right; width:10px">
                                                    <b>
                                                       {{ minTruck(truck.map(y => y.newunit).reduce( (p, v) => Number(p) + Number(v) ))}} Tonnes
                                                    </b>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Subtotal:</td>
                                                <td style="text-align:right; width:3px">
                                                    &#x20A6;
                                                </td>
                                                <td style="text-align:right; width:10px">
                                                    <b>
                                                       {{numTH(truck.map(x => x.product.price * x.qty).reduce( (p, v) => Number(p) + Number(v) ))}}
                                                    </b>
                                                </td>
                                            </tr>

                                            <tr v-if="deliveryMode != 'self'">
                                                <td>Freighted Cost:</td>
                                                <td style="text-align:right; width:3px">
                                                    &#x20A6;
                                                </td>
                                                <td style="text-align:right">
                                                    <b v-if="deliveryMode != 'self'">
                                                       {{  
                                                           thDFormat( 
                                                               (delivery_charges_server[i] = deliveryCharges(minTruck(truck.map(y => y.newunit).reduce( (p, v) => Number(p) + Number(v) )))) 
                                                            )                                                           
                                                        }}
                                                    </b>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Total Payable:</td>
                                                <td style="text-align:right; width:3px">
                                                    &#x20A6;
                                                </td>
                                                <td style="text-align:right">
                                                    <b v-if="deliveryMode != 'self'">
                                                        {{numTH( truck.map(x => x.product.price * x.qty).reduce( (p, v) => Number(p) + Number(v) ) + deliveryCharges(minTruck(truck.map(y => y.newunit).reduce( (p, v) => Number(p) + Number(v) ))) )}}
                                                    </b>
                                                    <b v-if="deliveryMode == 'self'">
                                                        {{numTH( truck.map(x => x.product.price * x.qty).reduce( (p, v) => Number(p) + Number(v) ) )}}
                                                    </b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>




                                <!-- <div class="col-lg-5"></div>
                                <div class="col-lg-3 bolder p-3" style="border-top: 1px solid #c7c8c92e;"> <span>Total Payable:</span></div>
                                <div class="col-lg-2 p-3" style="border-top: 1px solid #c7c8c92e;"></div>

                                <div v-if="deliveryMode != 'self'" class="col-lg-2 p-3 bolder" style="border-top: 1px solid #c7c8c92e;"><b>&#x20A6;{{numTH( truck.map(x => x.product.price * x.items).reduce( (p, v) => Number(p) + Number(v) ) + deliveryCharges(minTruck(truck.map(y => y.newunit).reduce( (p, v) => Number(p) + Number(v) ))) )}}</b></div>

                                <div v-if="deliveryMode == 'self'" class="col-lg-2 p-3 bolder" style="border-top: 1px solid #c7c8c92e;"><b>&#x20A6;{{numTH( truck.map(x => x.product.price * x.items).reduce( (p, v) => Number(p) + Number(v) ) )}}</b></div> -->
                            </div>   
                                    
                        </div>
                    </div>


                    <div class="row">
                        <!-- <div class="col">
                            <div class="mt-4 text-end"><button class="btn checkout-btn" @click="completeOrder()">&#x20A6;{{numTH(cart_total = sales_data.map(c => c.map(x => x.product.price * x.items).reduce( (p, v) => p + v)).reduce( (p, v) => p + v))}}</button></div>
                        </div> -->
                        <div class="col">
                            <div class="mt-4 text-end"><button class="btn checkout-btn" @click="completeOrder()">COMPLETE ORDER</button></div>
                        </div>
                    </div>
                </div>
                
                <div v-if="no_cart" class="text-center p-5">
                    <img src="@/assets/images/empty-cart.png" />
                    <p class="mt-5">Your cart is empty, please <a href="/products">click here</a> to add an item</p>
                </div>
                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import axios from 'axios';



export default {
    name: "Checkout",
    components: {
        SideNav, TopNav, Copyright
    },
    

    data() {
        return{
            "page_active": "orders",
            obj_product:{},
            product_list:[],
            obj_setorder:{},
            all_product_list:[],
            sub_acct:'',
            location:'',
            no_cart:false,
            sales_data:[],
            cart_total:0,
            deliveryMode:0,
            oms_updated_total:0,
            oms_pending_total:0,
            delivery_rate:[],

            delivery_charges_server: []
            
        }
    },


    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Checkout";
            },
        },
    },

    mounted () {
        
        this.inactivityTime(); 
        this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false}); // preloader

        this.sub_acct = this.$store.getters.sub_acct;
        this.location = this.$store.getters.collectionarea;  
        this.deliveryMode = this.$store.getters.deliveryMode;

        if(this.deliveryMode != 'self'){
            this.fetchfreightcharges();
        }


        this.getUpdatedOrders();
        this.getPendingOrders();


        this.loadProduct();

    },

    computed: {
        cartitem() {
            return this.$store.getters.cart;
        },        

        cartarray (){
            return this.$store.getters.cartdata;
        },

        sumTotal(){
            return this.product_list.reduce( (acc, product) =>  acc += product.qty * product.price  , 0);
        },

        finalTotal(){
            return this.sumTotal;
        },

        totalTons(){
           return this.cartarray.reduce((a, b) => a + Number(b.unit), 0);
        },
    },


    methods: {

        async getUpdatedOrders () {
            try {                  
                
                let params = {"status":"MODIFIED", "sub_accountID": this.sub_acct, "subaccountID":this.$store.getters.sub_acct};
                let data = this.loadNew(params);            
                await axios({
                    method: "get",
                    url: this.$hostname+"/modifysalesorder",
                    params: {'token':data},
                    headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    if(res.data.data.data.length > 0){
                        this.oms_updated_total =  parseFloat(res.data.data.data.map(x => x.amount).reduce( (p, v) => Number(p) + Number(v) ) + res.data.data.data.map(x => x.freighted_charge).reduce( (p, v) => Number(p) + Number(v) ));

                        
                        // freighted_charge
                        console.log("updated_total", this.oms_updated_total);
                    }
                    else{
                        this.oms_updated_total = 0;                        
                        console.log("updated_total", this.oms_updated_total);
                    }

                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },
    
        async getPendingOrders () {
            try {                
                let params = {"status":"PENDING", "sub_accountID": this.sub_acct,"subaccountID":this.$store.getters.sub_acct};
                let data = this.loadNew(params);

                await axios({
                    method: "get",
                    url: this.$hostname+"/modifysalesorder",
                    params: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    if(res.data.data.data.length > 0){
                        this.oms_pending_total = parseFloat(res.data.data.data.map(x => x.amount).reduce( (p, v) => Number(p) + Number(v) ) + res.data.data.data.map(x => x.freighted_charge).reduce( (p, v) => Number(p) + Number(v) ));

                        console.log('pending_total',this.oms_pending_total);
                    }
                    else{
                        this.oms_pending_total = 0;
                        console.log('pending_total',this.oms_pending_total);
                    }

                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },
    
        fetchfreightcharges() {
            try {                          
                
                let collectionCategory = this.$store.getters.collectionCategory;
                let subacct = this.$store.getters.sub_acct;
                let allacct = this.$store.getters.subaccounts;

                let account_info = allacct.filter( el => el.customeraccount == subacct ); 
                let destination = account_info[0].addresses[this.$store.getters.deliveryaddress].city;
                
                console.log(params = {
                        "Destination":  destination, 
                        "siteid": collectionCategory,
                        "city": destination,
                        "subaccountID":this.$store.getters.sub_acct 
                });

                let params = {
                        "Destination":  destination, 
                        "siteid": collectionCategory,
                        "city": destination,
                        "subaccountID":this.$store.getters.sub_acct 
                };
                let data = this.loadNew(params);

                axios({
                    method: "get",
                    url: this.$hostname+"/fetchfreightcharges",
                    params: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                        this.delivery_rate = res.data.data;
                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },

        async loadProduct () {
            try {
                this.obj_product.location     = this.location;
                this.obj_product.subaccountID = this.sub_acct;
                // this.obj_product.subaccountID = this.$store.getters.sub_acct
                
                let data = this.loadNew(this.obj_product);

                await axios({
                    method: "get",
                    url: this.$hostname+"/productcatalogueselfcollection",
                    params:  {'token':data},
                    headers: { "Content-Type": "application/json" ,
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })
                .then(res => {
                    this.all_product_list = res.data.data;
                    let product_list = this.all_product_list.filter( el => this.cartitem.includes(el.itemID));

                    let key = 'itemID';

                    let arrayUniqueByKey = [...new Map(product_list.map(item =>
                    [item[key], item])).values()];

                    this.product_list = arrayUniqueByKey;


                    if(this.product_list.length == 0){
                        this.no_cart = true;
                    }
                    this.generateSales();
                    this.$swal.close();
                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })  

                .finally(e => {                 
                    console.log(e);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },

        unitCount(x){
           return this.cartarray.filter( el => el.itemID == x )[0].unit;
        },

        numTH(x){
            return this.thDFormat(Number(x));
        },

        async setOrders(){
            
            this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false, allowOutsideClick: false}); // preloader
            //collect the object
            //send the object and finalTotal to server
            //validate response - successful or failed;
            try {
                let subacct = this.$store.getters.sub_acct;
                let allacct = this.$store.getters.subaccounts;
                let account_info = allacct.filter( el => el.customeraccount == subacct ); 

                this.obj_setorder.fn = 'setOrders';

                // this.obj_setorder.items = this.product_list;
                // this.obj_setorder.total = this.finalTotal;
                // this.obj_setorder.selected_truck = this.$store.getters.selected_truck;

                this.obj_setorder.collectionarea = this.$store.getters.collectionarea;
                this.obj_setorder.deliveryMode = this.$store.getters.deliveryMode;
                this.obj_setorder.deliveryaddress = this.$store.getters.deliveryaddress;

                this.obj_setorder.sub_acct = this.$store.getters.sub_acct;
                this.obj_setorder.salesdata = this.sales_data;
                this.obj_setorder.account = account_info;
                this.obj_setorder.deliverydata = this.delivery_charges_server;
                this.obj_setorder.subaccountID = this.$store.getters.sub_acct;
                
                this.obj_setorder.collection_category = this.$store.getters.collectionCategory;
                
                if(this.deliveryMode != 'self'){
                    this.obj_setorder.delivery_city = account_info[0].addresses[this.$store.getters.deliveryaddress].city;
                }

                let new_obj_setorder = this.loadNew(this.obj_setorder);

                await axios({
                    method: "post",
                    // url: "https://fmnplc.website/fmn/app_data.php",
                    url: this.$hostname+"/create-order",
                    data: {'token':new_obj_setorder},
                    headers: { "Content-Type": "application/json" ,
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => { 
                    
                    this.$swal.close();
                    
                    this.response = res.data
                    if(this.response.status){                                   
                        // alert(this.response.message)
                         this.$swal.fire(
                                'Success Alert',
                                this.response.message,
                                'success',
                                3000
                            )


                        

                        this.$store.commit("updateCart", []);
                        this.$store.commit("updateCartdata", []);

                        this.product_list = []
                        this.no_cart = true;
                    }
                    else{                                                   
                        // alert(this.response.message) 
                        this.$swal.fire(
                                'Error Alert',
                                this.response.message,
                                'error'
                            )
                    }    
                    
                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            }   
        },

        completeOrder(){
            let final_total = Number(this.cart_total);

            let sub_acct_bal = Number(this.$store.getters.sub_acct_bal) - (Number(this.oms_pending_total) + Number(this.oms_updated_total));

            console.log("subact balance after deduction", sub_acct_bal)

            if(sub_acct_bal >= final_total){
               this.setOrders();
            }
            else{
                let cc = confirm('You do not have a sufficient balance for this transaction, \n\n\nDo you want to transfer funds from other accounts?');

                if(cc)
                    this.$router.push('/transfer-fund');
            }

        },

        getProductDetails(id){
            return this.product_list.find( x => x.itemID == id)
        },

        generateSales(){
            let all_products    = [];
            let leftover        = 0;
            let total_count     = 0;
            let space           = 0;

            this.cartarray.forEach(element => {
                let remainder = 0;

                if (all_products.length > 0){
                    let last_array =  all_products[all_products.length - 1];
                    
                    if (leftover) {
                        let last_item = last_array[last_array.length - 1];
                        
                        leftover        = Number(leftover);
                        element.unit    = Number(element.unit);
                        remainder       = (leftover + element.unit) > 30 ? 30 - leftover : element.unit;

                        all_products.push([
                            {
                                id: last_item.id,
                                newunit: leftover,
                                product: this.getProductDetails(last_item.id)
                            },
                            {
                                id: element.itemID,
                                newunit: remainder,
                                product: this.getProductDetails(element.itemID)
                            }
                        ])
                    }

                    let last_array_sum = last_array.map((x) => x.newunit).reduce((p, v) => Number(p) + Number(v)) 

                    space = last_array_sum > 30 ? 0 : 30 - last_array_sum

                    console.log(space, leftover, last_array_sum)

                    if (space) {
                        all_products[all_products.length - 1].push({
                            id: element.itemID,
                            newunit: space > element.unit ? element.unit : space,
                            product: this.getProductDetails(element.itemID)
                        })
                    }
                }

                let left = element.unit - remainder - space

                let division = parseFloat(left / 30)

                let count =  Math.floor(division)

                leftover = count > 0 ? left % 30 : 0
                
                for (let i = 0; i < count; i++ ){
                    all_products.push([{
                        id : element.itemID,
                        newunit: 30,
                        product: this.getProductDetails(element.itemID)
                    }])
                }

                total_count++

                if ((this.cartarray.length == total_count && leftover > 0) || (division > 0 && division < 1)) {
                    all_products.push([{
                        id : element.itemID,
                        newunit: left % 30,
                        product: this.getProductDetails(element.itemID)
                    }])
                }               
            });


            this.sales_data = all_products;
        },

        minTruck(x){
            if(x <= 5){
                return 5
            }
            if(x <= 10){
                return 10
            }
            if(x <= 15){
                return 15
            }
            else if(x <= 20 ){
                return 20
            }
            else if(x <= 30 ){
                return 30
            }
            else{
                return 30
            }
        },

        unitFormatter(x){
            // 
            let lastChar = x.substr(x.length - 1)

            if(x == 'gal'){
                return 'Gallon';
            }
            else{
                if(lastChar == 's'){
                    return x.slice(0, -1);
                }
                else{
                    return x;
                }
            }
            
        },

        deliveryCharges(y){
            if(this.delivery_rate.filter( (data) =>data.truckWeight.includes(y) ).length > 0){
                return Number(Number(this.delivery_rate.filter( (data) =>data.truckWeight.match(/\d+/)[0] == y )[0].rate).toFixed(2));
            }
            else{
                return 0.00;
            }
        }
    },

};
</script>