<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />

            <div class="heading-lead h3">  
                <b>Reset Pin</b>
            </div>

            <div class="content-body">
                <div class="row mt-3">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body mb-5">
                                <form  class="login-form" autocomplete="off" method="post"  v-on:submit.prevent="handlePin">

                                    <!-- OTP window -->
                                    <div class="row mb-3" v-if="otp_screen == 1">
                                        <div class="col-lg-12 text-center mt-4 mb-3">
                                            <h4>STEP 1 OF 2</h4>
                                            <p class="font-12">Kindly enter the OTP sent to your mobile phone.</p>
                                            <hr style="width:15%; margin: 0 auto;">
                                        </div>

                                        <div class="col-lg-6 offset-lg-3 mt-3">                             
                                            <div class="form-group">
                                                <span>Enter OTP</span>
                                                <input type="text" autocomplete="off" class="form-control" placeholder="Enter OTP" v-model="pinreset.otp" required>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 offset-lg-3">
                                            <div :class="msg_status1" v-html="msg_content1"></div>
                                            <button type="button" class="btn form-control login-btn mt-0" @click="confirmOTP();">Proceed</button>
                                        </div>
                                    </div>

                                    <div class="row" v-if="otp_screen == 2">
                                        <div class="col-lg-12">
                                            <button onclick="history.back()" class="btn btn-danger font-12"><i class="fa fa-arrow-left"></i> &nbsp;&nbsp;&nbsp; Close</button>
                                        </div>

                                        <div class="col-lg-12 text-center mt-1 mb-3">
                                            <h4>STEP 2 OF 2</h4>
                                            <p class="font-12">Kindly enter your new pin.</p>
                                            <hr style="width:15%; margin: 0 auto;">
                                        </div>

                                        <div class="col-lg-6 offset-lg-3 mt-3">
                                            <div class="form-group">
                                                <span>New Pin:</span>
                                                <input autocomplete="new-password"  onfocus="this.value=''" type="password" class="form-control" placeholder="Security PIN" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" minlength="4" maxlength="4" v-model="pinreset.pin1" required>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-6 offset-lg-3">
                                            <div class="form-group">
                                                <span>Confirm New Pin:</span>
                                                <input  autocomplete="new-password"  onfocus="this.value=''" type="password" class="form-control" placeholder="Confirm Security PIN" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" minlength="4" maxlength="4" v-model="pinreset.pin2" required>
                                            </div>
                                        </div>

                                        
                                        <div class="col-lg-6 offset-lg-3">
                                            <button type="submit" class="btn form-control login-btn mt-0">Reset Pin</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>  

                </div>


                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';

import axios from 'axios';


export default {
    name: "Profile",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            page_active:"profile",
            vendor_no:'',        
            pinreset:{},                      
            otp_screen:1,
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Reset Pin";
            },
        },
    },

    mounted () {  
        this.inactivityTime();       

        this.vendor_no  = this.$store.getters.vendor_no;
        this.requestOTP();
    },

    methods: {
        requestOTP () {
            try {
                this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false}); // preloader
                this.pinreset.vendor_no = this.vendor_no;


                    let data = this.loadNew(this.pinreset);
                axios({
                    method: "post",
                    url: this.$baseurl + "/resend-otp",
                    data: {'token':data},
                    headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  },
                }).then(res => {
                    if(res.data.status == true){
                        this.response = res.data;                        

                        this.$swal.fire(
                            'Success Alert',
                            this.response.message,
                            'success'
                        )
                    }
                    else{
                        this.$swal.fire(
                            'Alert',
                            res.data.message,
                            'error'
                        )
                    }
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!'
                    })
                    this.is401(error.response.status);
                }).finally(e => {
                    console.log(e);
                })
            }
            catch (err) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
                console.log(err);
            }            
        },

        confirmOTP(){
            try {
                if(this.pinreset.otp != undefined){
                    this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false});                    
                    this.pinreset.vendor_no = this.vendor_no;

                    let data = this.loadNew(this.pinreset);
                    axios({
                        method: "post",
                        url: this.$baseurl + "/confirm-otp",
                        data: {'token':data},
                        headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  },
                    }).then(res => {
                        this.response = res.data
                        if(this.response.status == true){
                            
                            this.otp_screen = 2;

                            this.$swal.fire(
                                'Success Alert',
                                this.response.message,
                                'success'
                            );
                        }
                        else{
                            this.$swal.fire(
                                'Error Alert',
                                this.response.message,
                                'error'
                            );
                        }                        
                    }).catch(error => {
                        console.log(error)
                        this.$swal.fire(
                            'Error Alert',
                            'Error occured',
                            'error'
                        );
                        this.is401(error.response.status);
                    }).finally(e => {                    
                        console.log(e);
                    })
                }
                else{
                    this.$swal.fire(
                        'Error Alert',
                        'Please enter your OTP.',
                        'error'
                    );
                }
            }
            catch (err) {
                this.$swal.fire(
                    'Error Alert',
                    'Error occured',
                    'error'
                );
            }     
        },

        handlePin () {
            try {
                if(this.pinreset.pin1 == this.pinreset.pin2 && this.pinreset.pin1 != undefined){
                    this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false});                

                    this.pinreset.vendor_id = this.vendor_no;

                    let data = this.loadNew(this.pinreset);
                    axios({
                        method: "post",
                        url: this.$hostname + "/reset-pin",
                        data: {'token':data},
                        headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  },
                    })

                    .then(res => {
                        this.response = res.data
                        if(this.response.status == true){
                            this.$swal.fire(
                                'Success Alert',
                                this.response.message,
                                'success'
                            )
                            this.pinreset = {};
                        }
                        else{
                            this.$swal.fire(
                                'Error Alert',
                                this.response.message,
                                'error'
                            );
                        }      
                    })

                    .catch(error => {
                        console.log(error)
                        this.$swal.fire(
                            'Error Alert',
                             error,
                            'error'
                        );
                        this.is401(error.response.status);
                    })

                    .finally(e => {                      
                        console.log(e);
                    })
                }
                else{
                    this.$swal.fire(
                        'Error Alert',
                        "New pin & confirm pin did not match.",
                        'error'
                    );
                } 
            }
            catch (err) {
                this.$swal.fire(
                    'Error Alert',
                     err,
                    'error'
                );
            }            
        },
    },

    created () {
    },
};
</script>

<style scoped>
.login-form input.form-control, .select {
    margin: 0 0 20px 0px;
    border: 1px solid #c8c9ca;
    height: 40px;
    font-size: 12px;
    border-radius: 2px;
}
.login-form span {
    font-size: 12px;
    font-weight: 500;
}
</style>

