<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><b>Support</b></h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Support</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>

                <div class="row mt-5">

                    
                        <div class="col-lg-6 mt-3">

                            <div>
                                <h3 class="mb-0" style="font-weight:600;color: #000;">FAQ</h3>
                                <div class="font-14">Frequently Asked Questions.</div>
                            </div>


                            <div class="accordion mt-3" id="accordionExample">
                                <div class="accordion-item mb-1 border">
                                    <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Can I make payment for my goods on the E- Commerce portal?
                                    </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Payment for goods are made through your customer account with FMN. However, you can fund your account through e- bills via your bank’s branch or internet banking platform.
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="accordion-item mb-1 border">
                                    <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Can I obtain my account statement from the E- Commerce portal?
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Yes. Account statement for pre- defined periods can be viewed on the portal and exported as a PDF file.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item mb-1 border">
                                    <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Can I modify or cancel my order?
                                    </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Yes, orders that are yet to be confirmed can be modified or cancelled directly on the portal. However, confirmed orders cannot be modified but you can request to have it cancelled.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item mb-1 border">
                                    <h2 class="accordion-header" id="heading4">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                        Can I contact my account officer via the e- commerce portal?
                                    </button>
                                    </h2>
                                    <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Account officer names and phone numbers can be obtained on the portal, but account officers cannot be contact directly via the portal.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item mb-1 border">
                                    <h2 class="accordion-header" id="heading5">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                        My order has been dispatched; can I track it on the E- Commerce portal?
                                    </button>
                                    </h2>
                                    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Only freighted orders can be tracked, every customer will however receive an SMS informing them of an estimated ETA once their order has been confirmed.
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="accordion-item mb-1 border">
                                    <h2 class="accordion-header" id="heading6">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                        FAQ 6
                                    </button>
                                    </h2>
                                    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div> -->


                            </div>
                        </div>


                    <div class="col-lg-6 d-none">
                        <div class="card">
                            <div class="card-body">
                                <div class="p-2 border bolder mt-2 bg-secondary card">
                                    <div class=" row">
                                        <div class="col-lg-10">Quality issues?</div>

                                        <div class="col-lg-2 text-end">
                                            <svg width="30" height="30" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.9641 35C22.4245 37.6667 18.5755 37.6667 17.0359 35L6.21058 16.25C4.67098 13.5833 6.59549 10.25 9.67469 10.25L31.3253 10.25C34.4045 10.25 36.329 13.5833 34.7894 16.25L23.9641 35Z" fill="#1F1B1B"/>
                                            <path d="M14.6996 20.2181C14.6992 20.0132 14.7665 19.8147 14.8898 19.657C14.9592 19.5682 15.0445 19.4948 15.1407 19.4411C15.2369 19.3873 15.3422 19.3541 15.4505 19.3435C15.5589 19.333 15.6681 19.3451 15.772 19.3794C15.8758 19.4136 15.9723 19.4692 16.0559 19.543L20.4887 23.4705L24.9297 19.6833C25.0143 19.6105 25.1116 19.5561 25.2161 19.5233C25.3206 19.4905 25.4301 19.4799 25.5385 19.4921C25.6469 19.5043 25.7519 19.5391 25.8475 19.5945C25.9431 19.6499 26.0275 19.7247 26.0958 19.8148C26.171 19.9055 26.2278 20.0117 26.2626 20.1268C26.2973 20.2419 26.3092 20.3633 26.2975 20.4835C26.2859 20.6037 26.2509 20.72 26.1949 20.8252C26.1388 20.9304 26.0628 21.0222 25.9717 21.0947L21.0097 25.3291C20.8617 25.458 20.6761 25.5285 20.4845 25.5285C20.293 25.5285 20.1073 25.458 19.9594 25.3291L14.9973 20.9457C14.8972 20.8578 14.8181 20.746 14.7664 20.6196C14.7147 20.4932 14.6918 20.3556 14.6996 20.2181Z" fill="white"/>
                                            </svg>

                                        </div>

                                        <div class="col"><br><br><br>
                                        <span>
                                            Whether a customer is paying by credit/debit card,netbanking, or one of the several digital wallets that exist today, the failure.
                                        </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-2 border bolder mt-2">
                                    <div class=" row">
                                        <div class="col-lg-10">Can’t access my profile page settings?</div>

                                        <div class="col-lg-2 text-end">
                                            <svg width="30" height="30" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M47 23.0359C49.6667 24.5755 49.6667 28.4245 47 29.9641L19.25 45.9856C16.5833 47.5252 13.25 45.6007 13.25 42.5215L13.25 10.4785C13.25 7.39934 16.5833 5.47483 19.25 7.01443L47 23.0359Z" fill="#FFD300"/>
                                            <path d="M26.1353 33.9981C25.8705 33.9986 25.6139 33.9116 25.41 33.7522C25.2952 33.6624 25.2004 33.5522 25.1308 33.4278C25.0613 33.3035 25.0185 33.1674 25.0048 33.0273C24.9911 32.8873 25.0068 32.7461 25.0511 32.6118C25.0953 32.4776 25.1672 32.3528 25.2626 32.2448L30.3397 26.5147L25.444 20.7738C25.3498 20.6645 25.2795 20.5386 25.2371 20.4036C25.1947 20.2685 25.181 20.1269 25.1968 19.9868C25.2126 19.8467 25.2576 19.711 25.3292 19.5873C25.4008 19.4637 25.4975 19.3547 25.614 19.2664C25.7312 19.1691 25.8685 19.0957 26.0173 19.0508C26.166 19.0059 26.323 18.9905 26.4784 19.0056C26.6337 19.0207 26.7841 19.0658 26.9201 19.1383C27.0561 19.2108 27.1747 19.309 27.2685 19.4268L32.7422 25.8412C32.9089 26.0324 33 26.2724 33 26.52C33 26.7676 32.9089 27.0076 32.7422 27.1989L27.0759 33.6132C26.9622 33.7426 26.8178 33.8448 26.6543 33.9117C26.4909 33.9786 26.3131 34.0082 26.1353 33.9981Z" fill="black"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-2 border bolder mt-2">
                                    <div class=" row">
                                        <div class="col-lg-10">Can’t access my profile page settings?</div>

                                        <div class="col-lg-2 text-end">
                                            <svg width="30" height="30" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M47 23.0359C49.6667 24.5755 49.6667 28.4245 47 29.9641L19.25 45.9856C16.5833 47.5252 13.25 45.6007 13.25 42.5215L13.25 10.4785C13.25 7.39934 16.5833 5.47483 19.25 7.01443L47 23.0359Z" fill="#FFD300"/>
                                            <path d="M26.1353 33.9981C25.8705 33.9986 25.6139 33.9116 25.41 33.7522C25.2952 33.6624 25.2004 33.5522 25.1308 33.4278C25.0613 33.3035 25.0185 33.1674 25.0048 33.0273C24.9911 32.8873 25.0068 32.7461 25.0511 32.6118C25.0953 32.4776 25.1672 32.3528 25.2626 32.2448L30.3397 26.5147L25.444 20.7738C25.3498 20.6645 25.2795 20.5386 25.2371 20.4036C25.1947 20.2685 25.181 20.1269 25.1968 19.9868C25.2126 19.8467 25.2576 19.711 25.3292 19.5873C25.4008 19.4637 25.4975 19.3547 25.614 19.2664C25.7312 19.1691 25.8685 19.0957 26.0173 19.0508C26.166 19.0059 26.323 18.9905 26.4784 19.0056C26.6337 19.0207 26.7841 19.0658 26.9201 19.1383C27.0561 19.2108 27.1747 19.309 27.2685 19.4268L32.7422 25.8412C32.9089 26.0324 33 26.2724 33 26.52C33 26.7676 32.9089 27.0076 32.7422 27.1989L27.0759 33.6132C26.9622 33.7426 26.8178 33.8448 26.6543 33.9117C26.4909 33.9786 26.3131 34.0082 26.1353 33.9981Z" fill="black"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-2 border bolder mt-2">
                                    <div class=" row">
                                        <div class="col-lg-10">Can’t access my profile page settings?</div>

                                        <div class="col-lg-2 text-end">
                                            <svg width="30" height="30" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M47 23.0359C49.6667 24.5755 49.6667 28.4245 47 29.9641L19.25 45.9856C16.5833 47.5252 13.25 45.6007 13.25 42.5215L13.25 10.4785C13.25 7.39934 16.5833 5.47483 19.25 7.01443L47 23.0359Z" fill="#FFD300"/>
                                            <path d="M26.1353 33.9981C25.8705 33.9986 25.6139 33.9116 25.41 33.7522C25.2952 33.6624 25.2004 33.5522 25.1308 33.4278C25.0613 33.3035 25.0185 33.1674 25.0048 33.0273C24.9911 32.8873 25.0068 32.7461 25.0511 32.6118C25.0953 32.4776 25.1672 32.3528 25.2626 32.2448L30.3397 26.5147L25.444 20.7738C25.3498 20.6645 25.2795 20.5386 25.2371 20.4036C25.1947 20.2685 25.181 20.1269 25.1968 19.9868C25.2126 19.8467 25.2576 19.711 25.3292 19.5873C25.4008 19.4637 25.4975 19.3547 25.614 19.2664C25.7312 19.1691 25.8685 19.0957 26.0173 19.0508C26.166 19.0059 26.323 18.9905 26.4784 19.0056C26.6337 19.0207 26.7841 19.0658 26.9201 19.1383C27.0561 19.2108 27.1747 19.309 27.2685 19.4268L32.7422 25.8412C32.9089 26.0324 33 26.2724 33 26.52C33 26.7676 32.9089 27.0076 32.7422 27.1989L27.0759 33.6132C26.9622 33.7426 26.8178 33.8448 26.6543 33.9117C26.4909 33.9786 26.3131 34.0082 26.1353 33.9981Z" fill="black"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>

                    <div class="col-lg-6">
                        <div class="card mb-5">
                            <div class="card-body">
                                <h3>CONTACT US</h3>
                                <p class="bolder">Submit your message below.</p>
                                
                                <form class="login-form" autocomplete="off" method="post"  v-on:submit.prevent="createCase">
                                    <div class="form-group">
                                        <b>Title:</b> 
                                        <select type="text" class="form-control select" v-model="case_obj.title" required>
                                            <option v-for="(x,i) in category" :key="i">{{x}}</option>
                                        </select>
                                    </div>

                                    <div class="form-group mt-3">
                                        <b>Message:</b>
                                        <textarea type="email" class="form-control textarea" v-model="case_obj.description" placeholder="Type here" required ></textarea>
                                    </div>

                                    <!-- <div :class="msg_status" v-html="msg_content"></div> -->

                                    <button type="submit" class="btn col-4 login-btn">SUBMIT</button>

                                </form>
                            </div>

                            <div class="col-lg-3 text-center offset-lg-4 mt-3">
                            </div>                    
                        </div>                    
                    </div>




                    <div class="col-lg-6 d-none">
                        <div class="card">
                            <div class="card-body">
                                <div class="p-2 border bolder mt-2 bg-secondary card">
                                    <div class=" row">
                                        <div class="col-lg-10">Quality issues?</div>

                                        <div class="col-lg-2 text-end">
                                            <svg width="30" height="30" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.9641 35C22.4245 37.6667 18.5755 37.6667 17.0359 35L6.21058 16.25C4.67098 13.5833 6.59549 10.25 9.67469 10.25L31.3253 10.25C34.4045 10.25 36.329 13.5833 34.7894 16.25L23.9641 35Z" fill="#1F1B1B"/>
                                            <path d="M14.6996 20.2181C14.6992 20.0132 14.7665 19.8147 14.8898 19.657C14.9592 19.5682 15.0445 19.4948 15.1407 19.4411C15.2369 19.3873 15.3422 19.3541 15.4505 19.3435C15.5589 19.333 15.6681 19.3451 15.772 19.3794C15.8758 19.4136 15.9723 19.4692 16.0559 19.543L20.4887 23.4705L24.9297 19.6833C25.0143 19.6105 25.1116 19.5561 25.2161 19.5233C25.3206 19.4905 25.4301 19.4799 25.5385 19.4921C25.6469 19.5043 25.7519 19.5391 25.8475 19.5945C25.9431 19.6499 26.0275 19.7247 26.0958 19.8148C26.171 19.9055 26.2278 20.0117 26.2626 20.1268C26.2973 20.2419 26.3092 20.3633 26.2975 20.4835C26.2859 20.6037 26.2509 20.72 26.1949 20.8252C26.1388 20.9304 26.0628 21.0222 25.9717 21.0947L21.0097 25.3291C20.8617 25.458 20.6761 25.5285 20.4845 25.5285C20.293 25.5285 20.1073 25.458 19.9594 25.3291L14.9973 20.9457C14.8972 20.8578 14.8181 20.746 14.7664 20.6196C14.7147 20.4932 14.6918 20.3556 14.6996 20.2181Z" fill="white"/>
                                            </svg>

                                        </div>

                                        <div class="col"><br><br><br>
                                        <span>
                                            Whether a customer is paying by credit/debit card,netbanking, or one of the several digital wallets that exist today, the failure.
                                        </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-2 border bolder mt-2">
                                    <div class=" row">
                                        <div class="col-lg-10">Can’t access my profile page settings?</div>

                                        <div class="col-lg-2 text-end">
                                            <svg width="30" height="30" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M47 23.0359C49.6667 24.5755 49.6667 28.4245 47 29.9641L19.25 45.9856C16.5833 47.5252 13.25 45.6007 13.25 42.5215L13.25 10.4785C13.25 7.39934 16.5833 5.47483 19.25 7.01443L47 23.0359Z" fill="#FFD300"/>
                                            <path d="M26.1353 33.9981C25.8705 33.9986 25.6139 33.9116 25.41 33.7522C25.2952 33.6624 25.2004 33.5522 25.1308 33.4278C25.0613 33.3035 25.0185 33.1674 25.0048 33.0273C24.9911 32.8873 25.0068 32.7461 25.0511 32.6118C25.0953 32.4776 25.1672 32.3528 25.2626 32.2448L30.3397 26.5147L25.444 20.7738C25.3498 20.6645 25.2795 20.5386 25.2371 20.4036C25.1947 20.2685 25.181 20.1269 25.1968 19.9868C25.2126 19.8467 25.2576 19.711 25.3292 19.5873C25.4008 19.4637 25.4975 19.3547 25.614 19.2664C25.7312 19.1691 25.8685 19.0957 26.0173 19.0508C26.166 19.0059 26.323 18.9905 26.4784 19.0056C26.6337 19.0207 26.7841 19.0658 26.9201 19.1383C27.0561 19.2108 27.1747 19.309 27.2685 19.4268L32.7422 25.8412C32.9089 26.0324 33 26.2724 33 26.52C33 26.7676 32.9089 27.0076 32.7422 27.1989L27.0759 33.6132C26.9622 33.7426 26.8178 33.8448 26.6543 33.9117C26.4909 33.9786 26.3131 34.0082 26.1353 33.9981Z" fill="black"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-2 border bolder mt-2">
                                    <div class=" row">
                                        <div class="col-lg-10">Can’t access my profile page settings?</div>

                                        <div class="col-lg-2 text-end">
                                            <svg width="30" height="30" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M47 23.0359C49.6667 24.5755 49.6667 28.4245 47 29.9641L19.25 45.9856C16.5833 47.5252 13.25 45.6007 13.25 42.5215L13.25 10.4785C13.25 7.39934 16.5833 5.47483 19.25 7.01443L47 23.0359Z" fill="#FFD300"/>
                                            <path d="M26.1353 33.9981C25.8705 33.9986 25.6139 33.9116 25.41 33.7522C25.2952 33.6624 25.2004 33.5522 25.1308 33.4278C25.0613 33.3035 25.0185 33.1674 25.0048 33.0273C24.9911 32.8873 25.0068 32.7461 25.0511 32.6118C25.0953 32.4776 25.1672 32.3528 25.2626 32.2448L30.3397 26.5147L25.444 20.7738C25.3498 20.6645 25.2795 20.5386 25.2371 20.4036C25.1947 20.2685 25.181 20.1269 25.1968 19.9868C25.2126 19.8467 25.2576 19.711 25.3292 19.5873C25.4008 19.4637 25.4975 19.3547 25.614 19.2664C25.7312 19.1691 25.8685 19.0957 26.0173 19.0508C26.166 19.0059 26.323 18.9905 26.4784 19.0056C26.6337 19.0207 26.7841 19.0658 26.9201 19.1383C27.0561 19.2108 27.1747 19.309 27.2685 19.4268L32.7422 25.8412C32.9089 26.0324 33 26.2724 33 26.52C33 26.7676 32.9089 27.0076 32.7422 27.1989L27.0759 33.6132C26.9622 33.7426 26.8178 33.8448 26.6543 33.9117C26.4909 33.9786 26.3131 34.0082 26.1353 33.9981Z" fill="black"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-2 border bolder mt-2">
                                    <div class=" row">
                                        <div class="col-lg-10">Can’t access my profile page settings?</div>

                                        <div class="col-lg-2 text-end">
                                            <svg width="30" height="30" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M47 23.0359C49.6667 24.5755 49.6667 28.4245 47 29.9641L19.25 45.9856C16.5833 47.5252 13.25 45.6007 13.25 42.5215L13.25 10.4785C13.25 7.39934 16.5833 5.47483 19.25 7.01443L47 23.0359Z" fill="#FFD300"/>
                                            <path d="M26.1353 33.9981C25.8705 33.9986 25.6139 33.9116 25.41 33.7522C25.2952 33.6624 25.2004 33.5522 25.1308 33.4278C25.0613 33.3035 25.0185 33.1674 25.0048 33.0273C24.9911 32.8873 25.0068 32.7461 25.0511 32.6118C25.0953 32.4776 25.1672 32.3528 25.2626 32.2448L30.3397 26.5147L25.444 20.7738C25.3498 20.6645 25.2795 20.5386 25.2371 20.4036C25.1947 20.2685 25.181 20.1269 25.1968 19.9868C25.2126 19.8467 25.2576 19.711 25.3292 19.5873C25.4008 19.4637 25.4975 19.3547 25.614 19.2664C25.7312 19.1691 25.8685 19.0957 26.0173 19.0508C26.166 19.0059 26.323 18.9905 26.4784 19.0056C26.6337 19.0207 26.7841 19.0658 26.9201 19.1383C27.0561 19.2108 27.1747 19.309 27.2685 19.4268L32.7422 25.8412C32.9089 26.0324 33 26.2724 33 26.52C33 26.7676 32.9089 27.0076 32.7422 27.1989L27.0759 33.6132C26.9622 33.7426 26.8178 33.8448 26.6543 33.9117C26.4909 33.9786 26.3131 34.0082 26.1353 33.9981Z" fill="black"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>

                    <div class="col-lg-7 mt-5 d-none">
                        <div class="card p-3">
                            <b>Customer Care Line: +234807848979</b>
                        </div>
                    </div>

                    <div class="col-lg-5">
                     
                     <span class="chat-btn">
                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.5 0.25H3.5C1.7125 0.25 0.25 1.7125 0.25 3.5V32.75L6.75 26.25H29.5C31.2875 26.25 32.75 24.7875 32.75 23V3.5C32.75 1.7125 31.2875 0.25 29.5 0.25ZM29.5 23H6.75L3.5 26.25V3.5H29.5V23Z" fill="#006E40"/>
                        </svg>
                     </span>



                    </div>
                </div>
                




                <div class="row filter-wrap mb-3 d-none">
                    <div class="col-lg-12 mt-2">
                        <div class="filter-area">
                            <i class="fa fa-filter"></i>

                            <span class="filter-span"><i class="fa fa-check"></i>&nbsp;&nbsp; Status</span>

                        </div>

                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                        </div>
                                    </th>
                                    <th scope="col">TICKET ID</th>
                                    <th scope="col">COMPLAINTS</th>
                                    <th scope="col">STATUS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                        </div>
                                    </th>
                                    <td>Golden Penny</td>
                                    <td>20</td>
                                    <td>Open</td>
                                </tr>
                                <tr>
                                    <th scope="row">                                        
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                        </div>
                                    </th>
                                    <td>Pasta</td>
                                    <td>500</td>
                                    <td>In Progress</td>
                                </tr>
                                <tr>
                                    <th scope="row">                                        
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                        </div>
                                    </th>
                                    <td>Flour</td>
                                    <td>1500</td>
                                    <td>Resolved</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="filter-area text-end">
                            <b>Rows per page</b> &nbsp;&nbsp;&nbsp;

                            <select  class="form-control" style="width:50px; display: inline; margin: 0 30px;  border: 2px solid #006e40; height: 30px; padding: 0 10px">
                                <option>10</option>
                                <option >25</option>
                                <option>100</option>
                                <option>500</option>
                            </select>
                            
                            <b>1 - 10 of 100</b>&nbsp;&nbsp;

                            <i class="fa fa-step-backward"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                            <i class="fa fa-chevron-left"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                            <i class="fa fa-chevron-right"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                            <i class="fa fa-step-forward"></i>&nbsp;&nbsp;&nbsp;&nbsp;


                        </div>
                    </div>
                </div>

                <div class="row mt-5">


                    <div class="col-lg-5">
                     
                     <span class="chat-btn">
                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.5 0.25H3.5C1.7125 0.25 0.25 1.7125 0.25 3.5V32.75L6.75 26.25H29.5C31.2875 26.25 32.75 24.7875 32.75 23V3.5C32.75 1.7125 31.2875 0.25 29.5 0.25ZM29.5 23H6.75L3.5 26.25V3.5H29.5V23Z" fill="#006E40"/>
                        </svg>
                     </span>



                    </div>
                </div>



                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import axios from 'axios';



export default {
    name: "Complaints",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            page_active:"complaints",
            access_token:'',
            contactid:'',
            user_obj:{},
            case_obj:{},
            
            category:['Enquiry', 'Service Request']
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Dashboard";
            },
        },
    },

    mounted () {  
        this.inactivityTime();       
        this.accessToken();
    },

    methods: {
        accessToken () {
            try {
                this.user_obj.subaccountID = this.$store.getters.sub_acct;
                let data = this.loadNew(this.user_obj);

                axios({
                    method: "post",
                    url: this.$hostname+"/access-token",
                    data: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    this.access_token = res.data.access_token;
                    this.fetchCRMuser();
                })

                .catch(error => {
                    console.log(error)
                })
            }
            catch (err) {
                console.log(err)
            }       
        },

        fetchCRMuser () {
            this.user_obj.phone = this.$store.getters.phone;
            this.user_obj.token = this.access_token;

                this.user_obj.subaccountID = this.$store.getters.sub_acct;
            let data = this.loadNew(this.user_obj);
            try {
                axios({
                    method: "post",
                    url: this.$hostname+"/fetch-crm-user",
                    data: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    this.contactid = res.data.value[0].contactid;  
                })

                .catch(error => {
                    console.log(error)
                })
            }
            catch (err) {
                console.log(err)
            }       
        },

        createCase (){
            // this.case_obj.title = 'New Title';
            this.case_obj.customerid = "contacts("+this.contactid+")"  //this.contactid; //
            // this.case_obj.description = 'New Desc';
            this.case_obj.token = this.access_token;            
            this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false}); // preloader

                this.case_obj.subaccountID = this.$store.getters.sub_acct;
            let data = this.loadNew(this.case_obj);
            try {
                axios({
                    method: "post",
                    url: this.$hostname+"/create-crm-case",
                    data: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    
                    this.$swal.close();
                    
                    if(res.data.status == '204'){
                        // alert('Your case was submitted successfully')
                         this.$swal.fire(
                                'Success Alert',
                                'Your case was submitted successfully',
                                'success'
                            )
                        
                    }
                    else{
                        // alert('Cannot process your case, please try again.') 
                        this.$swal.fire(
                                'Error Alert',
                                'Cannot process your case, please try again.',
                                'error'
                            )
                    }

                    
                })

                .catch(error => {
                    console.log(error);
                })
            }
            catch (err) {
                console.log(err);
            }      
        }

    },

    created () {
    }
};
</script>

<style scoped>
.login-form input.form-control, .textarea, .select {
    margin: 0px 0px 24px;
    font-size: 12px;
}

.chat-btn {
    padding: 19px 20px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #f2f2f2;
    box-shadow: 8px 11px 15px 4px #d8d5d5;
    position: fixed;
    bottom: 64px;
    right: 30px;
    z-index: 100;
}

.accordion-button:focus {
    z-index: 3;
    border-color: #006e4066;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(0 110 64 / 26%);
}

.accordion-button {
    height: 48px;
}

.accordion-button:not(.collapsed) {
    color: #000;
    background-color: #fff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    font-weight: 600;
}
</style>

