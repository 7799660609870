import Vue from 'vue'
import VueRouter from 'vue-router'

// Authentication vue import
import Login                      from '../views/Login.vue'
import ForgotPassword             from '../views/ForgotPassword.vue'
import ResetPassword              from '../views/ResetPassword.vue'
import Registration               from '../views/Registration.vue'
import OTPValidation              from '../views/OTPValidation.vue'
import ForgotPasswordSuccess      from '../views/ForgotPasswordSuccess.vue'


import Home                 from '../views/Home.vue'
import DeliveryMode               from '../views/DeliveryMode.vue'


// Main App vue import
import Dashboard                  from '../views/Dashboard.vue'


// Product Util vue import
import Cart                       from '../views/Cart.vue'
import Orders                     from '../views/Orders.vue'
import Delivery                     from '../views/Delivery.vue'

import Products              from '../views/Products.vue'

import Wishlists                  from '../views/Wishlists.vue'
import TrackingOrder              from '../views/TrackingOrder.vue'
import SelectTruck                from '../views/SelectTruck.vue'
import Checkout                   from '../views/Checkout.vue'
import SalesOrder                 from '../views/SalesOrder.vue'

// Wallet Util vue import
import Wallet             from '../views/Wallet.vue'
import FundWallet         from '../views/FundWallet.vue'
import TransferFund       from '../views/TransferFund.vue'
import AccountStatement   from '../views/AccountStatement.vue'
import AccountPreview     from '../views/AccountPreview.vue'

// Profile util vue import
import Profile            from '../views/Profile.vue'
import EditProfile        from '../views/EditProfile.vue'
import AddAddress         from '../views/AddAddress.vue'
import AddLocation        from '../views/AddLocation.vue'
import EditAddress        from '../views/EditAddress.vue'
import Complaints         from '../views/Complaints.vue'
import ProductDetails     from '../views/ProductDetails.vue'
import ProfileView     from '../views/ProfileView.vue'

import ResetPin     from '../views/ResetPin.vue'


import ContactUs     from '../views/ContactUs.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },

  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/otp-validation',
    name: 'OTPValidation',
    component: OTPValidation
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/mode-of-delivery',
    name: 'DeliveryMode',
    component: DeliveryMode
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders
  },
  {
    path: '/delivery',
    name: 'Delivery',
    component: Delivery
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/wishlists',
    name: 'Wishlists',
    component: Wishlists
  },
  {
    path: '/track-order',
    name: 'TrackingOrder',
    component: TrackingOrder
  },
  {
    path: '/select-truck',
    name: 'SelectTruck',
    component: SelectTruck
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/salesorder',
    name: 'SalesOrder',
    component: SalesOrder
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet
  },
  {
    path: '/fund-wallet',
    name: 'FundWallet',
    component: FundWallet
  },
  {
    path: '/transfer-fund',
    name: 'TransferFund',
    component: TransferFund
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: EditProfile
  },
  {
    path: '/add-address',
    name: 'AddAddress',
    component: AddAddress
  },
  {
    path: '/add-location',
    name: 'AddLocation',
    component: AddLocation
  },
  {
    path: '/edit-address',
    name: 'EditAddress',
    component: EditAddress
  },
  {
    path: '/complaints',
    name: 'Complaints',
    component: Complaints
  },
  {
    path: '/view-profile',
    name: 'ProfileView',
    component: ProfileView
  },
  {
    path: '/product-details/:id',
    name: 'ProductDetails',
    component: ProductDetails
  },
  {
    path: '/reset-password-success',
    name: 'ForgotPasswordSuccess',
    component: ForgotPasswordSuccess
  },
  {
    path: '/account-statement/:id',
    name: 'AccountStatement',
    component: AccountStatement
  },
  {
    path: '/account-preview/:id',
    name: 'AccountPreview',
    component: AccountPreview
  },
  {
    path: '/contact-us/',
    name: 'ContactUs',
    component: ContactUs
  },

  {
    path: '/reset-pin',
    name: 'ResetPin',
    component: ResetPin
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router