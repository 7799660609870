<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6 col-12">
                        <h6><a href="/profile" title="Go back to profile"><i class="fa fa-arrow-left text-dark"></i></a> &nbsp;&nbsp;&nbsp;&nbsp;  <b>Add Delivery Address</b></h6>
                    </div>

                    <div class="col-lg-6  d-none d-sm-none d-lg-block">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                                <li class="breadcrumb-item"><a href="/profile">Profile</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Add Address</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-5 form-cover mb-5">
                                        <p>Please fill the information to apply for delivery address changes.</p>
                                        
                                        <form  class="login-form" autocomplete="off" method="post">
                                            <div class="form-group">
                                                <!-- <label>Name</label> -->
                                                <span>Fullname:</span>
                                                <input type="text" autofocus class="form-control" placeholder="Fullname" required />
                                                <i class="far fa-user"></i>
                                            </div>

                                            
                                            <div class="form-group">
                                                <!-- <label>Phone Number</label> -->
                                                <span>Phone Number:</span>
                                                <input type="tel" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  minlength="11" maxlength="11" class="form-control" placeholder="Phone Number: e.g. 08012345678" required>
                                                <i class="fa fa-phone"></i>
                                            </div>

                                            <div class="form-group">
                                                <!-- <label>Email</label> -->
                                                <span>Address:</span>
                                                <textarea class="form-control textarea" placeholder="Address" required ></textarea>
                                            </div>

                                            <div :class="msg_status" v-html="msg_content"></div>

                                            <button type="submit" class="btn form-control login-btn">ADD ADDRESS</button>

                                        </form>
                                    </div>

                                    <div class="col-lg-3 text-center offset-lg-4 mt-3">
                                    </div>
                                </div>
                            </div>                        
                        </div>                    
                    </div>
                </div>
 
                <Copyright />
            </div>
        </div>
    </div>
</template>


<script>
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import Copyright from '@/components/Copyright.vue';


export default {
    name: "AddAddress",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            "page_active": "profile"
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Add Address";
            },
        },
    },

    mounted () {    
        this.inactivityTime();     
        this.renderChart(this.chartdata, this.options)
    },

    methods: {

    },

    created () {
    }
};
</script>

<style scoped>
.login-form input.form-control, .textarea {
    margin: 0px 0px 24px;
    font-size: 12px;
}
</style>