<template>
    <div class="container-fluid">
        <div class="row">
            
            <DashboardNav v-bind:page_active="page_active"/>



            <div class="main-section">
               
                
                <!-- greetings -->
                <TimeGreetings />

                <div class="content-body" style="top: 0px;">


                    <!-- page breadcrumb -->
                    <div class="row breadcrumb-area">
                        <div class="col-lg-6">
                            <!-- <h3 style="font-weight:600">HELP</h3> -->
                        </div>

                        <div class="col-lg-6">
                            <nav aria-label="breadcrumb mt-2">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/home">Dashboard</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Help</li>
                                </ol>
                            </nav>                   
                        </div>
                    </div>


                    <div class="row mt-3">                            

                        <div class="col-lg-6 mt-3">

                            <div>
                                <h3 class="mb-0" style="font-weight:600;color: #000;">FAQ</h3>
                                <div class="font-14">Frequently Asked Questions.</div>
                            </div>


                            <div class="accordion mt-3" id="accordionExample">
                                <div class="accordion-item mb-1 border">
                                    <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Can I make payment for my goods on the E- Commerce portal?
                                    </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Payment for goods are made through your customer account with FMN. However, you can fund your account through e- bills via your bank’s branch or internet banking platform.
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="accordion-item mb-1 border">
                                    <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Can I obtain my account statement from the E- Commerce portal?
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Yes. Account statement for pre- defined periods can be viewed on the portal and exported as a PDF file.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item mb-1 border">
                                    <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Can I modify or cancel my order?
                                    </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Yes, orders that are yet to be confirmed can be modified or cancelled directly on the portal. However, confirmed orders cannot be modified but you can request to have it cancelled.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item mb-1 border">
                                    <h2 class="accordion-header" id="heading4">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                        Can I contact my account officer via the e- commerce portal?
                                    </button>
                                    </h2>
                                    <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Account officer names and phone numbers can be obtained on the portal, but account officers cannot be contact directly via the portal.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item mb-1 border">
                                    <h2 class="accordion-header" id="heading5">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                        My order has been dispatched; can I track it on the E- Commerce portal?
                                    </button>
                                    </h2>
                                    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Only freighted orders can be tracked, every customer will however receive an SMS informing them of an estimated ETA once their order has been confirmed.
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="accordion-item mb-1 border">
                                    <h2 class="accordion-header" id="heading6">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                        FAQ 6
                                    </button>
                                    </h2>
                                    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div> -->


                            </div>
                        </div>


                        <div class="col-lg-6 mt-3">
                            
                            <div>
                                <h3 class="mb-0" style="font-weight:600;color: #000;">CONTACT US</h3>
                                <div class="font-14">Leave us a message.</div>
                            </div>
                            
                            <form class="login-form mt-3" autocomplete="off" method="post"  v-on:submit.prevent="createCase">
                                <div class="form-group">
                                    <select type="text" class="form-control select mt-0 mb-1" v-model="formdata.inquire" required>
                                        <option value="" disabled="">What is your inquiry about?  </option>
                                        <option value="General">General Enquiry  </option>
                                        <option value="Complaints">Complaints  </option>
                                    </select>
                                </div>

                                
                                <div class="form-group mt-0">
                                    <select type="text" class="form-control select mt-0 mb-1" required>
                                        <option>Select your Account?  </option>
                                        <option v-for="(x,i) in subprofiles" :key="i" :value="x.customeraccount"> {{x.customeraccount}} ~ {{x.customername}} </option>
                                    </select>
                                </div>

                                <!-- v-if="formdata.inquire == 'Complaints'" -->
                                <div class="form-group mt-0">
                                    <select type="text"  v-model="case_obj.title" class="form-control select mt-0 mb-1" required>
                                        <option>What is your complaint about?  </option>
                                        <option v-for="(x,i) in category" :key="i">{{x}}</option>
                                    </select>
                                </div>


                                <div class="form-group mt-0">
                                    <textarea v-model="case_obj.description" class="form-control textarea m-0" :placeholder="formdata.inquire == 'Complaints' ? 'What is your complaints?':'What is your message?'" required  style="height:267px"></textarea>
                                </div>

                                <!-- <div :class="msg_status" v-html="msg_content"></div> -->

                                <button type="submit" class="btn col-4 login-btn mt-2">SUBMIT</button>

                            </form>           
                        </div>

                    </div>

                </div>
            </div>



            <!-- import footer -->
            <div class="row bg-white mt-5">
                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import DashboardNav from '@/components/DashboardNav.vue';
import Copyright from '@/components/Copyright.vue';
import TimeGreetings from '@/components/TimeGreetings.vue';
import axios from 'axios';


export default {
    name: "ContactUs",
    components: {
        DashboardNav, Copyright, TimeGreetings
    },
    
    data() {
        return{       
            page_active:"contact",     
            account : {
                customername: '',
                telephone: "",
            },
            account_id:'',
            subprofiles:[{"customername":""}],
            formdata:{"inquire":""},

            
            access_token:'',
            contactid:'',
            user_obj:{},
            case_obj:{},

            
            category:['Enquiry', 'Service Request']
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Account Statement";
            },
        },
    },

    mounted () {  
        this.inactivityTime();               
        this.subprofiles =  this.$store.getters.subaccounts;

        this.account_id = this.$route.params.id;
        this.account = this.$store.getters.subaccounts.find((c) => c.customeraccount == this.account_id );

        this.accessToken();
    },

    methods: {
        accessToken () {
            try {
                
                let data = this.loadNew({'subaccountID':this.$store.getters.subaccounts[0].customeraccount});
                axios({
                    method: "post",
                    url: this.$hostname+"/access-token",
                    data: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    this.access_token = res.data.access_token;
                    this.fetchCRMuser();
                })

                .catch(error => {
                    console.log(error)   
                    this.is401(error.response.status);
                })  
            }
            catch (err) {
                console.log(err)
            }       
        },

        fetchCRMuser () {
            this.user_obj.phone = this.$store.getters.phone;
            this.user_obj.token = this.access_token;

            this.user_obj.subaccountID = this.$store.getters.subaccounts[0].customeraccount;
            let data = this.loadNew(this.user_obj);
            try {
                axios({
                    method: "post",
                    url: this.$hostname+"/fetch-crm-user",
                    data: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    this.contactid = res.data.value[0].contactid;  
                })

                .catch(error => {
                    console.log(error)   
                    this.is401(error.response.status);
                })  
            }
            catch (err) {
                console.log(err)
            }       
        },

        createCase (){
            // this.case_obj.title = 'New Title';
            this.case_obj.customerid = "contacts("+this.contactid+")"  //this.contactid; //
            // this.case_obj.description = 'New Desc';
            this.case_obj.token = this.access_token;
            
            this.case_obj.subaccountID = this.$store.getters.subaccounts[0].customeraccount;
            this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false}); // preloader


            let data = this.loadNew(this.case_obj);
            try {
                axios({
                    method: "post",
                    url: this.$hostname+"/create-crm-case",
                    data: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    
                    this.$swal.close();
                    
                    if(res.data.status == '204'){
                        // alert('Your case was submitted successfully')
                         this.$swal.fire(
                                'Success Alert',
                                'Your case was submitted successfully',
                                'success'
                            )
                        
                    }
                    else{
                        // alert('Cannot process your case, please try again.') 
                        this.$swal.fire(
                                'Error Alert',
                                'Cannot process your case, please try again.',
                                'error'
                            )
                    }

                    
                })

                .catch(error => {
                    console.log(error)   
                    this.is401(error.response.status);
                })  
            }
            catch (err) {
                console.log(err);
            }      
        }
    },

    created () {
        
    },

    computed: {
    },
};
</script>

<style scoped>
label{
    position: absolute;
    top: -15px;
    font-weight: 500;
    font-size: 12px;
}
.login-form .form-check-input {
    border: 2px solid #14142B;
    border-radius: 8px !important;
}
ul {
    padding-left: 10px;
    margin-bottom: 0;
}
.side-nav-bg-cover {
    width: 30px;
    left: 0px;
    /* border-radius: 24px 0 0 24px; */
    /* top: 20px; */
    /* bottom: 10px; */
    /* height: auto !important; */
}
.side-nav {
    width: 80px;
    /* border-radius: 17px 25px 25px 17px; */
    /* top: 20px; */
    /* bottom: 10px; */
    /* left: 25px; */
}
.side-link.active > a {
    padding: 0 5px 0 25px;
}
.side-nav ul li a {
    color: #fff;
    display: block;
    padding: 0 5px 0px 25px;
}
.main-section {
    width: calc(100% - 80px);
    margin-left: 80px;

    /* background: url(https://demo.fmnplc.website/product_images/236-2369779_flour-png-wheat-flour-bag-png.png); */
    background-position: 45% 70%;
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
}
.side-link-after {
    padding: 15px 0px 20px !important;
}
/* .subprofile .card-body.active {
    border: 2px solid rgba(255, 255, 255, 0);
} */
.subprofile .card-body {
    box-shadow: none;
}
.dashboard-icon1{
 font-size: 24px;
    background: #000;
    padding: 12px 14px;
    border-radius: 25px;
    color: #ffd300;
    box-shadow: 1px 2px 6px 6px #f2f2f2;
    top: -5px;
}
.btn{
    background: #000;
    color: #fff;
}
.form-check-label {
    font-weight: 600;
    font-size: 12px;
}
.h1 {
    font-size: 26px;
    font-weight: 600;
}

.side-link.active, .container-fluid {
    background: #f6f6f6 !important;
}

.card{
    background: #fff;
}

.accordion-button:not(.collapsed) {
    color: #000;
    background-color: #fff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    font-weight: 600;
}

.login-form input.form-control, .select {
    border: 1px solid #dee2e6;
    border-radius: 0px;   
}

.textarea {
    border: 1px solid #dee2e6;
    border-radius: 0;
    padding: 20px 15px;
}

.accordion-button:focus {
    z-index: 3;
    border-color: #006e4066;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(0 110 64 / 26%);
}

.accordion-button {
    height: 48px;
}
</style>

