<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />


        <div class="main-section">

            <TopNav />


            <div class="content-body">
                <!-- Account Statistics -->
                <div class="row account-stats mt-">
                    <div class="col-12 mb-3">
                        <p><b class="sub-name">Account - {{customer_name}} ({{sub_acct}})</b></p>
                    </div>

                    <div class="col-lg-3 col-12 mb-2">
                        <div class="card p-3 border-left-success">
                            <div class="row">
                                <div class="col-3">
                                    <i class="far fa-user dashboard-icon1"></i>
                                </div>
                                <div class="col-9">
                                    <p class="card-text m-0"><b>Account ID</b></p>
                                    <h5 class="card-title"><b>{{sub_acct}}</b></h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-12 mb-2" :title="'Avaiable balance for account '+ sub_acct">
                        <div class="card p-3 border-left-success">
                            <div class="row">
                                <div class="col-3">
                                    <svg style="background:#006e40; box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15); border-radius:2px; width: 40px; height: 40px;  padding: 0px 10px; margin-left: 5px; margin-top: 2px;enable-background:new 0 0 496.262 496.262;"
                                        fill="#FFD300" version="1.1" id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 496.262 496.262"
                                        xml:space="preserve">
                                        <path
                                            d="M477.832,274.28h-67.743v-65.106h67.743c10.179,0,18.43-8.243,18.43-18.424c0-10.182-8.251-18.43-18.43-18.43h-67.743  V81.982c0-13.187-2.606-22.866-7.743-28.762c-4.882-5.609-11.301-8.219-20.19-8.219c-8.482,0-14.659,2.592-19.447,8.166  c-5.077,5.902-7.654,15.599-7.654,28.821v90.343H227.627l-54.181-81.988c-4.637-7.317-8.997-14.171-13.231-20.75  c-3.812-5.925-7.53-10.749-11.042-14.351c-3.109-3.189-6.652-5.657-10.796-7.554c-3.91-1.785-8.881-2.681-14.762-2.681  c-7.501,0-14.31,2.055-20.83,6.277c-6.452,4.176-10.912,9.339-13.636,15.785c-2.391,6.126-3.656,15.513-3.656,27.63v77.626h-67.07   C8.246,172.326,0,180.574,0,190.755c0,10.181,8.246,18.424,18.424,18.424h67.07v65.113h-67.07C8.246,274.292,0,282.538,0,292.722  C0,302.9,8.246,311.14,18.424,311.14h67.07v103.143c0,12.797,2.689,22.378,8.015,28.466c5.065,5.805,11.487,8.5,20.208,8.5  c8.414,0,14.786-2.707,20.07-8.523c5.411-5.958,8.148-15.533,8.148-28.442V311.14h115.308l62.399,95.683  c4.339,6.325,8.819,12.709,13.287,18.969c4.031,5.621,8.429,10.574,13.069,14.711c4.179,3.742,8.659,6.484,13.316,8.157  c4.794,1.726,10.397,2.601,16.615,2.601c16.875,0,34.158-5.166,34.158-43.479V311.14h67.743c10.179,0,18.43-8.252,18.43-18.43 C496.262,282.532,488.011,274.28,477.832,274.28z M355.054,209.173v65.106h-60.041l-43.021-65.106H355.054z M141.936,134.364  l24.76,37.956h-24.76V134.364z M141.936,274.28v-65.106h48.802l42.466,65.106H141.936z M355.054,365.153l-35.683-54.013h35.683  V365.153z" />
                                    </svg>
                                </div>
                                <div class="col-9">
                                    <p class="card-text m-0"><b>Available Balance</b></p>
                                    <h5 class="card-title"><b>&#x20A6;{{thDFormat(avaiableBalance)}}</b></h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-12 mb-2" :title="'Current balance for account '+ sub_acct">
                        <div class="card p-3  border-left-success">
                            <div class="row">
                                <div class="col-3">
                                    <svg style="background:#006e40; box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15); border-radius:2px; width: 40px; height: 40px;  padding: 0px 10px; margin-left: 5px; margin-top: 2px;enable-background:new 0 0 496.262 496.262;"
                                        fill="#FFD300" version="1.1" id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 496.262 496.262"
                                        xml:space="preserve">
                                        <path
                                            d="M477.832,274.28h-67.743v-65.106h67.743c10.179,0,18.43-8.243,18.43-18.424c0-10.182-8.251-18.43-18.43-18.43h-67.743  V81.982c0-13.187-2.606-22.866-7.743-28.762c-4.882-5.609-11.301-8.219-20.19-8.219c-8.482,0-14.659,2.592-19.447,8.166  c-5.077,5.902-7.654,15.599-7.654,28.821v90.343H227.627l-54.181-81.988c-4.637-7.317-8.997-14.171-13.231-20.75  c-3.812-5.925-7.53-10.749-11.042-14.351c-3.109-3.189-6.652-5.657-10.796-7.554c-3.91-1.785-8.881-2.681-14.762-2.681  c-7.501,0-14.31,2.055-20.83,6.277c-6.452,4.176-10.912,9.339-13.636,15.785c-2.391,6.126-3.656,15.513-3.656,27.63v77.626h-67.07   C8.246,172.326,0,180.574,0,190.755c0,10.181,8.246,18.424,18.424,18.424h67.07v65.113h-67.07C8.246,274.292,0,282.538,0,292.722  C0,302.9,8.246,311.14,18.424,311.14h67.07v103.143c0,12.797,2.689,22.378,8.015,28.466c5.065,5.805,11.487,8.5,20.208,8.5  c8.414,0,14.786-2.707,20.07-8.523c5.411-5.958,8.148-15.533,8.148-28.442V311.14h115.308l62.399,95.683  c4.339,6.325,8.819,12.709,13.287,18.969c4.031,5.621,8.429,10.574,13.069,14.711c4.179,3.742,8.659,6.484,13.316,8.157  c4.794,1.726,10.397,2.601,16.615,2.601c16.875,0,34.158-5.166,34.158-43.479V311.14h67.743c10.179,0,18.43-8.252,18.43-18.43 C496.262,282.532,488.011,274.28,477.832,274.28z M355.054,209.173v65.106h-60.041l-43.021-65.106H355.054z M141.936,134.364  l24.76,37.956h-24.76V134.364z M141.936,274.28v-65.106h48.802l42.466,65.106H141.936z M355.054,365.153l-35.683-54.013h35.683  V365.153z" />
                                    </svg>
                                </div>
                                <div class="col-9">
                                    <p class="card-text m-0"><b>Current Balance</b></p>
                                    <h5 class="card-title"><b>&#x20A6;{{thDFormat(currentBalance)}}</b></h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-12 mb-2" :title="'Credit limit for account '+ sub_acct">
                        <div class="card p-3 border-left-success">
                            <div class="row">
                                <div class="col-3">
                                    <svg style="background:#006e40; box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15); border-radius:2px; width: 40px; height: 40px;  padding: 0px 10px; margin-left: 5px; margin-top: 2px;enable-background:new 0 0 496.262 496.262;"
                                        fill="#FFD300" version="1.1" id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 496.262 496.262"
                                        xml:space="preserve">
                                        <path
                                            d="M477.832,274.28h-67.743v-65.106h67.743c10.179,0,18.43-8.243,18.43-18.424c0-10.182-8.251-18.43-18.43-18.43h-67.743  V81.982c0-13.187-2.606-22.866-7.743-28.762c-4.882-5.609-11.301-8.219-20.19-8.219c-8.482,0-14.659,2.592-19.447,8.166  c-5.077,5.902-7.654,15.599-7.654,28.821v90.343H227.627l-54.181-81.988c-4.637-7.317-8.997-14.171-13.231-20.75  c-3.812-5.925-7.53-10.749-11.042-14.351c-3.109-3.189-6.652-5.657-10.796-7.554c-3.91-1.785-8.881-2.681-14.762-2.681  c-7.501,0-14.31,2.055-20.83,6.277c-6.452,4.176-10.912,9.339-13.636,15.785c-2.391,6.126-3.656,15.513-3.656,27.63v77.626h-67.07   C8.246,172.326,0,180.574,0,190.755c0,10.181,8.246,18.424,18.424,18.424h67.07v65.113h-67.07C8.246,274.292,0,282.538,0,292.722  C0,302.9,8.246,311.14,18.424,311.14h67.07v103.143c0,12.797,2.689,22.378,8.015,28.466c5.065,5.805,11.487,8.5,20.208,8.5  c8.414,0,14.786-2.707,20.07-8.523c5.411-5.958,8.148-15.533,8.148-28.442V311.14h115.308l62.399,95.683  c4.339,6.325,8.819,12.709,13.287,18.969c4.031,5.621,8.429,10.574,13.069,14.711c4.179,3.742,8.659,6.484,13.316,8.157  c4.794,1.726,10.397,2.601,16.615,2.601c16.875,0,34.158-5.166,34.158-43.479V311.14h67.743c10.179,0,18.43-8.252,18.43-18.43 C496.262,282.532,488.011,274.28,477.832,274.28z M355.054,209.173v65.106h-60.041l-43.021-65.106H355.054z M141.936,134.364  l24.76,37.956h-24.76V134.364z M141.936,274.28v-65.106h48.802l42.466,65.106H141.936z M355.054,365.153l-35.683-54.013h35.683  V365.153z" />
                                    </svg>
                                </div>
                                <div class="col-9">
                                    <p class="card-text m-0"><b>Credit Limit</b></p>
                                    <h5 class="card-title"><b>&#x20A6;{{thDFormat(creditLimit)}}</b></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Graphical Analytics -->
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <div class="card shadow-sm">
                            <div class="card-header" style="display:block">
                                <span style="font-weight:600">Transactions from <b>{{lst6mt}}</b> to <b>{{today}}</b></span>
                            </div>
                            <div class="card-body" style="min-height: 375px;">
                                <bar-chart id="bar" :data="barData" :bar-colors="arrayColors" :xkey="'date'" :ykeys="ykeys" grid="true" resize="true" grid-text-weight="bold" ></bar-chart>
                            </div>  
                        </div>
                    </div>
                </div>

                <!-- filter side pop modal -->
                <!-- <FilterModal /> -->

                <!-- copyright section -->
                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
// import FilterModal from '@/components/FilterModal.vue';

import {BarChart} from 'vue-morris';

import moment from 'moment'


import axios from 'axios';
import JQuery from 'jquery'
window.$ = JQuery



export default {
    name: "Dashboard",

    components: {
        SideNav, TopNav, Copyright, BarChart
    },
    
    data() {
        return{
            page_active:"dashboard",
            order_list:[],
            transaction_history:[],
            subprofiles:[],

            obj_transaction:{},
            obj_order:{},

            subaccountid:' ',            
            customer_name:" ",
            sub_acct:'',

            currentBalance:0,
            creditRemaining:0,
            avaiableBalance:0,
            creditLimit:0,
            ordered:'',

            arrayColors:[ "#FF6384", "#73c000", "#FFCE56" ],

            barData: [
                { date: '', "amount": 0}
            ],

            ykeys:['amount'],
            xkey:['date'],
            lst6mt:'',
            today:'',
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Dashboard";
            },
        },
    },

    mounted() {
        this.inactivityTime(); 
        this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false}); // preloader

        if (this.$store.getters.vendor_no) {
            this.phone          = this.$store.getters.phone; 
            this.vendor_no      = this.$store.getters.vendor_no;    
            this.sub_acct       = this.$store.getters.sub_acct;     
            this.customer_name  = this.$store.getters.sub_acct_name;       
        }

        this.subprofiles        =  this.$store.getters.subaccounts;

        // this.getSalesOrders();  // load sales order history from AX
        this.getTransactions(); // load transaction history from AX
    },

    methods: {
        getTransactions () {
            this.today = moment().format('YYYY/MM/DD');
            this.lst6mt = moment().subtract(6, 'months').format('YYYY/MM/DD');

            this.obj_transaction.subaccountID = this.sub_acct;
            this.obj_transaction.startdate = this.lst6mt;
            this.obj_transaction.enddate = this.today;
            this.obj_transaction.page = 1;
            this.obj_transaction.pagesize = 5000;

                this.obj_transaction.subaccountID = this.$store.getters.sub_acct;

            let data = this.loadNew(this.obj_transaction);

            try {
                axios({
                    method: "get",
                    url: this.$hostname+"/getcustomertransactions",
                    params: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    let transactions = res.data;  

                    this.transaction_history    = transactions.data.transactionhistories; 
                    this.subaccountid           =  transactions.data.subaccountid;
                    this.currentBalance         =  transactions.data.balanceincurrency;
                    this.creditLimit            =  transactions.data.creditlimit;
                    this.avaiableBalance        =  transactions.data.creditremaining;
                    this.ordered                =  transactions.data.ordered;


                    let x = this.transaction_history;

                    for(let i = 0; i<x.length; i++){
                        let bar_obj = {};
                        bar_obj.date    = x[i].date;
                        bar_obj.amount  = x[i].amount;

                        //this.barData.push(bar_obj);
                    }

                    this.$swal.close();
                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            }       
        },

        getSalesOrders () {
            this.obj_order.subaccountID = this.sub_acct;
            this.obj_order.startdate = '2020/01/01';
            this.obj_order.enddate = '2020/09/09';
            this.obj_order.page = 1;
            this.obj_order.pagesize = 10;
            this.obj_order.linestatus = "";
                this.obj_order.subaccountID = this.$store.getters.sub_acct;

            let data = this.loadNew(this.obj_order);
            try {                                 
                axios({
                    method: "get",
                    url: this.$hostname+"/getsaleordertransactions",
                    params: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    this.order_list = res.data.data
                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },
    },

    computed: {
        totalBalance(){
            let sum = 0;
            for(let i = 0; i < this.subprofiles.length; i++){
                sum +=  Number(this.subprofiles[i].creditremaining);
            }
            return sum;
        }
    },

    created () {
       
    },
};
</script>

<style scoped>
    .ml10{margin-left:10px}
    .mr10{margin-right:10px}
</style>